import LoaderSvg from "../../assets/index.svg"
// import { FlipFlopLoader } from "react-awesome-loaders";
export const Loader  = () => {
  return (
    <>
      <div style={{height : "100vh" , width : "100%" , display : "flex" , justifyContent : "center" , alignItems : "center"}}>
        <img src={LoaderSvg} alt="loader" />

      </div>
    </>
  );
};
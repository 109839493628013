import { create } from "zustand"
import client from "./client"

type Opinion = {
  orders: any,
  order: any,
  getOrders: (params?: any) => Promise<any>,
  getOrder: (id: any, params?: any) => Promise<any>,
  createOrder: (input: any, options?: any) => Promise<any>,
  deleteOrder: (id: any) => Promise<any>
}


const useOrder = create<Opinion>(set => ({
  orders: [],
  order: {},
  getOrders: async (params) => {
    try
    {
      const response = await client.orders.getAll(params);
      set({ orders: response?.data });
      return response.data;
    } catch (error)
    {
      console.log("getOrders errors ---->", error);
      return error;
    }
  },
  getOrder: async (id, params) => {
    try
    {
      const response = await client.orders.getOne(id, params);
      set({ order: response?.data });
      return response;
    } catch (error)
    {
      console.log("getOrder errors ---->", error);
      return error;
    }
  },
  createOrder: async (input, options) => {
    try
    {
      const response = await client.orders.create(input, options);
      return response;
    } catch (error)
    {
      console.log("createOrder errors ---->", error);
      return error;
    }
  },
  deleteOrder: async (id) => {
    try
    {
      const response = await client.orders.delete(id);
      return response;
    } catch (error)
    {
      console.log("deleteOrder errors ---->", error);
      return error;
    }
  },
}))

export default useOrder
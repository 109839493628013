import { useFormik } from "formik";
import useLoginModal from "hooks/use-login-modal";
import useRegisterModal from "hooks/use-register-modal";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "data/auth";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import "../../../App.css";
import Alert from "components/service-provider/admin/partials/alert/warning";

export default function LoginModal() {
  const { isOpen, closeModal } = useLoginModal();
  const { openModal: openRegisterModal } = useRegisterModal();
  const [alertmessage , setAlerMessage] = useState<any>({
    message: '',
    type: '',
    title : '',
    isDangerousHTML : false
  }) 
  // useEffect(() => {
  //   let navZIndex = document.querySelector(".layoutHeader__nav") as HTMLElement;

  //   if (navZIndex) {
  //     navZIndex.style.zIndex = "0";
  //   }

  //   const closeModalHandler = (e: Event) => {
  //     if (e.target === document.querySelector(".modal__overlay")) {
  //       if (e.target !== document.querySelector(".modal__content")) {
  //         closeModal();
  //       }
  //     }
  //   };

  //   window.addEventListener("click", closeModalHandler, false);

  //   return () => {
  //     if (navZIndex) {
  //       navZIndex.style.zIndex = "20";
  //     }
  //     window.removeEventListener("click", closeModalHandler, false);
  //   };
  // }, [isOpen, closeModal]);
  const { login, socialLogin } = useAuth();
  const navigate = useNavigate();
  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (res) => {
      await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
            Authorization: `Bearer ${res.access_token}`,
          },
        })
        .then(async (res) => {
          let data = {
            email: res.data.email,
            first_name: res.data.family_name,
            last_name: res.data.given_name,
            full_name: res.data.name,
            password: res.data.sub,
            avatar: res.data.picture,
            social_id: res.data.sub,
            social_type: "google",
          };
          const response = await socialLogin(data);
          if (response?.success) {
            // reload();
            window.location.reload();
          }
          console.log("res", res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    flow: "implicit",
  });

  const responseFacebook = async (response: any) => {
    console.log("response", response);
    let data = {
      email: response.email,
      first_name: response.first_name,
      last_name: response.last_name,
      full_name: response.name,
      password: response.id,
      avatar: response.picture.data.url,
      social_id: response.id,
      social_type: "facebook",
    };
    const res = await socialLogin(data);
    if (res?.success) {
      // reload();
      window.location.reload();
    }
  };

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Adresse email invalide")
        .required("Veuillez entrer votre adresse email"),
      password: Yup.string().required("Veuillez entrer votre mot de passe"),
    }),
    onSubmit: async (values) => {
      const res = await login(values);
      console.log("res test", res);
      if (res.response?.status === 401) {
        setAlerMessage({
          message: 'Email ou mot de passe incorrect',
          type: 'warning',
          title : 'Attention',
          isDangerousHTML : false
        })
      }
      if (res.response?.status === 422) {
        const errors = res.response?.data?.errors;
        let messages = "";
        for (const key in errors) {
          messages += errors[key] + "<br />";
        }
        setAlerMessage({
          message: messages,
          type: 'error',
          title : 'Attention',
          isDangerousHTML : true
        })
      }
      if (res.data) {
        // reload();
        window.location.reload();
      }
    },
  });
  return (
    <>
      {isOpen && (
        <div className="modal " role="dialog">
          <div className="modal__content null">
            <section className="app-auth-form authModal">
              <div className="authModal__header">
                <p className="authModal__title">Accédez à votre compte</p>
                <i
                  className="svgIcon svgIcon__close authModal__closeIcon app-modal-close"
                  onClick={closeModal}
                >
                  <svg viewBox="0 0 26 26">
                    <path
                      d="M12.983 10.862L23.405.439l2.122 2.122-10.423 10.422 10.423 10.422-2.122 2.122-10.422-10.423L2.561 25.527.439 23.405l10.423-10.422L.439 2.561 2.561.439l10.422 10.423z"
                      fillRule="nonzero"
                    />
                  </svg>
                </i>{" "}
              </div>
              <div className="authModal__content">
                <div className="socialButtons app-auth-social-area-buttons">
                  <FacebookLogin
                    appId="887897829544510"
                    autoLoad={false}
                    callback={responseFacebook}
                    fields="name, email, picture,first_name,last_name"
                    scope="public_profile,email"
                    render={(renderProps: any) => (
                      <button
                      onClick={renderProps.onClick}
                      className="socialButtons--facebook btnOutline btnOutline--grey app-facebook-button mb10 mr15 btnOutline--full                 "
                      type="button"
                      style={{ position: "relative" , justifyContent: "center"}}
                    >
                     <div>
                     <i className="svgIcon svgIcon__facebook svgIcon--medium fleft"
                      style={{
                        marginLeft : "50px"
                      }}
                     >
                        <svg
                          width={12}
                          height={24}
                          viewBox="0 0 12 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.81 3.985H12.001V0.169C11.623 0.117 10.323 0 8.809 0C5.65 0 3.486 1.987 3.486 5.639V9H0V13.266H3.486V24H7.76V13.267H11.105L11.636 9.001H7.759V6.062C7.76 4.829 8.092 3.985 9.81 3.985V3.985Z"
                            fill="#335795"
                          />
                        </svg>
                      </i>{" "}
                      <hr 
                      style={{
                        width: "1px",
                        height: "20px",
                        backgroundColor: "#ccc",
                        margin: "0 10px",
                        position : "absolute",
                        top: "20%",
                        left: "20%"
                      }}
                      />
                     </div>
                      <p  style={{
                        marginRight : "55px"
                      }}>
                      <b>Poursuivre avec Facebook</b>
                      </p>
                    </button>
                    )}
                  />

<div
                  className="socialButtons--googleWrapper mb10"
                  onClick={() => handleGoogleLogin()}
                  style={{ position: "relative" , display: "flex" , justifyContent: "center"}}
                >
                  <span className="socialButtons--googleTopButton socialButtons--google btnOutline btnOutline--grey btnOutline--full">
                    <i className="svgIcon svgIcon__google svgIcon--medium fleft" style={{
                        marginLeft : "50px"
                      }}>
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.6875 10C4.6875 9.00913 4.96051 8.08088 5.4348 7.28608V3.91467H2.0634C0.725313 5.65249 0 7.77042 0 10C0 12.2296 0.725313 14.3476 2.0634 16.0854H5.4348V12.714C4.96051 11.9192 4.6875 10.9909 4.6875 10V10Z"
                          fill="#FBBD00"
                        />
                        <path
                          d="M10 15.3126L7.65625 17.6563L10 20.0001C12.2296 20.0001 14.3475 19.2747 16.0854 17.9367V14.5688H12.7175C11.9158 15.0449 10.9836 15.3126 10 15.3126V15.3126Z"
                          fill="#0F9D58"
                        />
                        <path
                          d="M5.43488 12.714L2.06348 16.0854C2.3284 16.4295 2.61688 16.759 2.92902 17.0711C4.81777 18.9598 7.32898 20 10.0001 20V15.3125C8.06164 15.3125 6.3627 14.2688 5.43488 12.714Z"
                          fill="#31AA52"
                        />
                        <path
                          d="M20 10C20 9.39164 19.9449 8.78211 19.8363 8.18844L19.7483 7.70801H10V12.3955H14.7442C14.2835 13.312 13.5752 14.0596 12.7175 14.5689L16.0853 17.9367C16.4294 17.6718 16.7589 17.3833 17.0711 17.0711C18.9598 15.1823 20 12.6711 20 10V10Z"
                          fill="#3C79E6"
                        />
                        <path
                          d="M13.7565 6.24348L14.1708 6.65777L17.4854 3.34324L17.0711 2.92895C15.1823 1.0402 12.6711 0 10 0L7.65625 2.34375L10 4.6875C11.419 4.6875 12.7531 5.24008 13.7565 6.24348Z"
                          fill="#CF2D48"
                        />
                        <path
                          d="M10.0001 4.6875V0C7.32902 0 4.81781 1.0402 2.92902 2.92891C2.61688 3.24105 2.3284 3.57055 2.06348 3.91465L5.43488 7.28605C6.36273 5.73125 8.06168 4.6875 10.0001 4.6875V4.6875Z"
                          fill="#EB4132"
                        />
                      </svg>
                    </i>{" "}
                    <hr 
                      style={{
                        width: "1px",
                        height: "20px",
                        backgroundColor: "#ccc",
                        margin: "0 10px",
                        position : "absolute",
                        top: "20%",
                        left: "20%"
                      }}
                      />
                   <p  style={{
                        marginRight : "70px"
                      }}>
                   <b>Poursuivre avec Google</b>
                   </p>
                  </span>
                  <div className="app-google-login-button socialButtons--googleHidden btnOutline--full app-google-login"></div>
                </div>
                </div>
                <p className="authModal__subtitle">
                  Ou connectez-vous avec votre email
                </p>
                {
                  alertmessage?.message && (
                    <Alert {...alertmessage} />

                  )
                }
                <form
                  className="authModalForm app-auth-form-login"
                  method="POST"
                  onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit(e);
                  }}
                >
                  <div className="app-form-control formControl authModalForm__input authModalForm__fullwidth">
                    <div
                      className="formField app-form-field  formField--outlined"
                      aria-labelledby="modalLogin_email"
                    >
                      <i className="svgIcon svgIcon__envelope formField__icon">
                        <svg viewBox="0 0 20 15">
                          <path
                            d="M18.912 12.604V1.995c0-.47-.406-.911-.8-.911H1.888c-.393 0-.799.441-.799.911v10.609c0 .466.402.896.8.896h16.225c.397 0 .799-.43.799-.896zm1 0c0 1.003-.834 1.896-1.8 1.896H1.888c-.965 0-1.799-.893-1.799-1.896V1.995C.088.991.923.084 1.888.084h16.225c.964 0 1.799.907 1.799 1.911v10.609zm-9.631-4.71a.5.5 0 01-.562.827L2.513 3.828a.5.5 0 01.562-.827l7.206 4.893zm7.18 1.85a.5.5 0 01-.552.834l-3.29-2.175a.5.5 0 11.552-.834l3.29 2.175zm-14.37.834a.5.5 0 01-.552-.834l3.29-2.175a.5.5 0 11.551.834l-3.29 2.175zm7.185-1.859a.5.5 0 11-.562-.827l7.206-4.893a.5.5 0 01.562.827l-7.206 4.893z"
                            fillRule="nonzero"
                          />
                        </svg>
                      </i>{" "}
                      <input
                        type="email"
                        name="email"
                        onChange={(e) => {
                          formik.handleChange(e);
                          if (e.target.value.length > 0) {
                            document
                              .getElementById("modalLogin_email")
                              ?.classList.add("focused");
                          }
                        }}
                        id="modalLogin_email"
                        className={"formField__input app-auth-login-email"}
                      />
                      <label
                        className="formField__label"
                        htmlFor="modalLogin_email"
                      >
                        Votre email
                      </label>
                    </div>
                    {formik.errors.email && formik.touched.email ? (
                      <div
                        className="formControl__hint"
                        id="modalLogin_emailError"
                      >
                        {formik.errors.email}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="app-form-control formControl authModalForm__input authModalForm__fullwidth">
                    <div
                      className="formField app-form-field  formField--outlined"
                      aria-labelledby="modalLogin_password"
                    >
                      <i className="svgIcon svgIcon__lock formField__icon">
                        <svg viewBox="0 0 18 18">
                          <path d="M8.99 0c2.336 0 4.546 2.025 4.546 4.312v3.665a6.094 6.094 0 011.444 3.953c0 3.351-2.68 6.07-5.99 6.07S3 15.281 3 11.93c0-1.51.544-2.89 1.444-3.953V4.312C4.444 2.025 6.654 0 8.99 0zm0 6.865c-2.752 0-4.985 2.266-4.985 5.065 0 2.8 2.233 5.065 4.985 5.065 2.752 0 4.986-2.266 4.986-5.065 0-2.799-2.234-5.065-4.986-5.065zm0 4.27a.796.796 0 01.503 1.41v2.023a.502.502 0 01-.997.09l-.008-.09v-2.024a.796.796 0 01.502-1.409zm0-10.13c-1.8 0-3.541 1.595-3.541 3.307v2.723A5.911 5.911 0 018.99 5.86c1.326 0 2.55.436 3.542 1.174V4.312c0-1.712-1.742-3.307-3.542-3.307z" />
                        </svg>
                      </i>{" "}
                      <input
                        type="password"
                        name="password"
                        id="modalLogin_password"
                        className="formField__input app-auth-login-password"
                        onChange={(e) => {
                          formik.handleChange(e);
                          if (e.target.value.length > 0) {
                            document
                              .getElementById("modalLogin_password")
                              ?.classList.add("focused");
                          }
                        }}
                      />
                      <label
                        className="formField__label"
                        htmlFor="modalLogin_password"
                      >
                        Mot de passe
                      </label>
                    </div>
                    {formik.errors.password && formik.touched.password ? (
                      <div
                        className="formControl__hint"
                        id="modalLogin_passwordError"
                      >
                        {formik.errors.password}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>{" "}
                  <a role="button" className="authModalForm__text">
                    Mot de passe oublié ?
                  </a>
                  <div className="authModalForm__submit">
                    <button
                      className="button button--primary button--block app-auth-login-submit"
                      style={{ width: "100%" }}
                      type="submit"
                    >
                      Se connecter
                    </button>
                  </div>
                </form>
                <p className="authModal__phrase">
                  Vous n'avez pas de compte ?{" "}
                  <span
                    role="button"
                    className="authModal__link app-auth-signup-switch-signup"
                    data-section="default"
                    onClick={() => {
                      closeModal();
                      openRegisterModal();
                    }}
                  >
                    Inscription
                  </span>
                </p>
                <p className="authModal__phrase authModal__phrase--disableMobile">
                  Vous êtes un professionnel ?{" "}
                  <Link className="authModal__link" to={"/service-provider"}>
                    Accès entreprises
                  </Link>
                </p>
              </div>
            </section>
          </div>
          <div className="modal__overlay app-modal-overlay" />
        </div>
      )}
    </>
  );
}

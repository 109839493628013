// import "./detail.css";
// import "./prestatires-detail.css";
// import "./App.css";
import { renderRoutes } from "routes/index.tsx";
import { clientRoutes } from "routes/client-routes.tsx";
import { AdminRoutes } from "routes/admin-routes.tsx";
import { BrowserRouter } from "react-router-dom";
import React, { Suspense, useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "react-image-gallery/styles/css/image-gallery.css";
import useAuth from "data/auth";
import { Loader } from "components/common/loader";
import LoginModal from "components/auth/partials/login-modal";
import RegisterModal from "components/auth/partials/register-modal";
import DeleteModal from "components/service-provider/admin/partials/modal/delete-modal";
import PromotionModal from "components/client/partials/modal/promotion-modal";
import OrderModal from "components/client/partials/modal/order-modal";
import ContactModal from "components/client/partials/modal/contact-modal";

function App() {
  const routes = [...clientRoutes, ...AdminRoutes];
  const { getCurrentUser } = useAuth();

  useEffect(() => {
    (async () => {
      await getCurrentUser();
    })();
    fetch("https://ipapi.co/json/")
      .then(response => response.json())
      .then(data => {
         localStorage.setItem('userCountry', JSON.stringify(data));
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  } , []);

  return (
    <>
      <GoogleOAuthProvider
        clientId={
          "154764164864-v0kt1ijgh94q0cq2238jp2irebaeuepf.apps.googleusercontent.com"
        }
      >
        <BrowserRouter>
          <Suspense fallback={<Loader />}>
            <LoginModal />
            <RegisterModal />
            <DeleteModal />
            <PromotionModal />
            <OrderModal />
            <ContactModal />
            {renderRoutes(routes)}
          </Suspense>
        </BrowserRouter>
      </GoogleOAuthProvider>
    </>
  );
}

export default App;

import { create } from "zustand";
import client from "./client";
import { handlingErrors } from "libs/helper";
import { setLoggedUser } from "./client/utils";

interface AuthProps {
  currentUser: any;
  isLoading: boolean;
  isAuth: boolean;
  forgotPassword: (params: any) => Promise<any>;
  resetPassword: (params: any) => Promise<any>;
  login: (params: any) => Promise<any>;
  getCurrentUser: () => Promise<any>;
  logout: () => Promise<any>;
  register: (params: any) => Promise<any>;
  socialLogin: (params: any) => Promise<any>;
  changeSocialLogin: (params: any) => Promise<any>;
  completeProfile: (params: any) => Promise<any>;
}

const useAuth = create<AuthProps>((set) => ({
  currentUser: null,
  isLoading: false,
  isAuth: false,
  forgotPassword: async (params) => {
    try
    {
      const response = await client.users.forgotPassword(params);
      console.log('forgotPassword response ---->', response);
      return response;
    }
    catch (error)
    {
      console.log('forgotPassword errors ---->', error);
      return error;
    }
  },
  resetPassword: async (params) => {
    try
    {
      const response = await client.users.resetPassword(params);
      console.log('resetPassword response ---->', response);
      return response;
    }
    catch (error)
    {
      console.log('resetPassword errors ---->', error);
      return error;
    }
  },
  login: async (params) => {
    try
    {
      const response = await client.users.login(params);
      setLoggedUser(response?.data);
      set({ currentUser: response.data, isLoading: false, isAuth: true });
      console.log('login response ---->', response);
      return response;
    }
    catch (error)
    {
      console.log('login errors ---->', error);
      return error;
    }
  },
  getCurrentUser: async () => {
    set({ isLoading: true });
    try
    {
      const response = await client.users.getCurrentUser();
      set({ currentUser: response?.data, isLoading: false, isAuth: true });
      setLoggedUser(response?.data);
      console.log('user response ---->', response);
      return useAuth.getState().currentUser;
    }
    catch (error)
    {
      console.log('getCurrentUser errors ---->', error);
      return error;
    }
  },
  logout: async () => {
    try
    {
      const response = await client.users.logout();
      setLoggedUser(null);
      set({ currentUser: null, isLoading: false });
      window.location.href = '/login';
      console.log('logout response ---->', response);
      return response;
    }
    catch (error)
    {
      console.log('logout errors ---->', error);
    }
  },
  register: async (params, formik = null) => {
    try
    {
      const response = await client.users.register(params);
      let token = response?.data?.token as string;
      setLoggedUser(response?.data);
      set({ currentUser: response?.data, isLoading: false });
      console.log('register response ---->', response);
      return response;
    }
    catch (error: any)
    {
      console.log('register errors ---->', error);
      if (formik)
      {
        handlingErrors(error?.response, formik);
      }
      return error
    }
  },
  socialLogin: async (params) => {
    try
    {
      const response = await client.users.socialLogin(params);
      setLoggedUser(response?.data);
      set({ currentUser: response?.data, isLoading: false });
      console.log('googleLogin response ---->', response);
      return response;
    }
    catch (error)
    {
      console.log('googleLogin errors ---->', error);
      return error;
    }
  },
  changeSocialLogin: async (params) => {
    try
    {
      const response = await client.users.changeSocialLogin(params);
      setLoggedUser(response?.data);
      set({ currentUser: response?.data, isLoading: false });
      console.log('changeSocialLogin response ---->', response);
      return response;
    }
    catch (error)
    {
      console.log('changeSocialLogin errors ---->', error);
      return error;
    }
  },
  completeProfile: async (params, formik = null) => {
    try
    {
      const response = await client.users.register(params);
      let token = response?.data?.token as string;
      setLoggedUser(response?.data);
      set({ currentUser: response?.data, isLoading: false });
      console.log('register response ---->', response);
      return response;
    }
    catch (error: any)
    {
      console.log('register errors ---->', error);
      if (formik)
      {
        handlingErrors(error?.response, formik);
      }
      return error
    }
  },
}));

export default useAuth;
import dotenv from "dotenv";


export const ConfigValue = {
  URL : "https://soleraa.switch-maker.net",
  API_URL: "http://localhost:8000/api/",
  // API_URL: "https://api-soleraa.switch-maker.net/api/",
  API_STORAGE_URL: "http://localhost:8000/storage/",
  // API_STORAGE_URL: "https://api-soleraa.switch-maker.net/storage/",
  AUTH_USER_KEY: "soleraa-user",
  TYNY_API_KEY : "hjzi65pl2txld2gjsqa5fxqgh9kshgk9rbv9rkf2k1h0esgx",
  MAP_API_KEY : "AIzaSyBAqGsTJMj6FlWWAN3QRTt-2lEKyEoQM8Q"
};
import { create } from "zustand";

type TopAlertType = {
  isOpen: boolean;
  alert: {
    title: string;
    description: string;
    type: string;
  };
  setAlert: (alert: {
    title: string;
    description: string;
    type: string;
  }) => void;
  openAlert: () => void;
  closeAlert: () => void;
};

const useTopAlert = create<TopAlertType>((set) => ({
  isOpen: false,
  alert: {
    title: "",
description: "",
    type: "",
  },
  setAlert: (alert) => set({ alert }),
  openAlert: () => set({ isOpen: true }),
  closeAlert: () => set({ isOpen: false }),
}));

export default useTopAlert;
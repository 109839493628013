import { useFormik } from "formik";
import useLoginModal from "hooks/use-login-modal";
import useRegisterModal from "hooks/use-register-modal";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "data/auth";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// import "../../../App.css";
import Alert from "components/service-provider/admin/partials/alert/warning";
import usePromotionModal from "hooks/use-promotion-modal";
import useProfile from "data/profile";
import classNames from "classnames";
import useOrderModal from "hooks/use-order-modal";
import useOrder from "data/order";
import useTopAlert from "hooks/use-top-alert";
import { removeScroll } from "libs/helper";

export default function OrderModal() {
  const { isOpen, closeModal, data } = useOrderModal();
  const { setAlert } = useTopAlert()
  const { openModal: openLoginModal } = useLoginModal();
  const [alertmessage, setAlerMessage] = useState<any>({
    message: "",
    type: "",
    title: "",
    isDangerousHTML: false,
  });
  const { userProfile } = useProfile();
  const { createOrder } = useOrder();
  const formik = useFormik({
    initialValues: {
      announcement_id: data?.id,
      quantity: 1,
      delivery_date: "",
      message: "",
      status: "",
      total_price: data?.price * 1,
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {
      const res = await createOrder({
        ...values,
        announcement_id: data?.id,
        status: "pending",
        total_price: data?.price * values.quantity,
      });
      if (res.success) {
        closeModal();
        setAlert({
          type: "success",
          description: "Votre commande a été envoyée avec succès",
          title: "Félicitations",
        });
        removeScroll()
      }
    },
  });
  return (
    <>
      {isOpen && (
        <div className="modal " role="dialog" aria-modal="true">
          <div className="modal__content app-lead-form-modal-content">
            <section className="leadFormModal leadFormModal--outlined">
              <div className="leadFormModal__header">
                <div>
                  <p className="leadFormModal__subtitle">
                    {data?.announcement_name}
                  </p>
                  <h1 className="leadFormModal__title">Lancer une commande</h1>
                </div>
                <i
                  className="svgIcon svgIcon__close leadFormModal__closeIcon app-modal-close"
                  onClick={closeModal}
                >
                  <svg viewBox="0 0 26 26">
                    <path
                      d="M12.983 10.862L23.405.439l2.122 2.122-10.423 10.422 10.423 10.422-2.122 2.122-10.422-10.423L2.561 25.527.439 23.405l10.423-10.422L.439 2.561 2.561.439l10.422 10.423z"
                      fillRule="nonzero"
                    />
                  </svg>
                </i>{" "}
              </div>
              <div className="leadFormModal__content">
                <p className="leadFormModal__description">
                  Remplissez le formulaire suivant pour lancer une commande
                  Soleraa vous contactera pour finaliser votre commande
                </p>
                <form
                  method="POST"
                  className="leadForm leadFormAside__form--outlined dealLeadFormModal__form app-deals-bf-lead app-internal-tracking-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit(e);
                  }}
                >
                  <div className="leadForm__content app-lead-form-content leadForm__content--visibleScroll">
                    <div className="app-form-control formControl leadForm__fullwidth">
                      <div className="formField app-form-field  formField--outlined">
                        <i className="svgIcon svgIcon__user formField__icon">
                          <svg viewBox="0 0 47 53">
                            <path
                              d="M31.722 29.786C40.49 32.99 46.78 42.236 46.78 51.962v1H.846v-1c0-9.615 6.156-18.77 14.774-22.069-2.971-1.686-5.407-4.324-6.774-7.691-3.236-7.981.75-17.009 8.891-20.172 8.13-3.154 17.343.736 20.575 8.698 2.915 7.181-.03 15.22-6.59 19.058zm-12.16 1.698l-2.469.006c-7.922 2.633-13.8 10.765-14.222 19.472h41.885c-.423-8.744-6.343-16.9-14.309-19.501l-2.786.006a16.09 16.09 0 01-8.099.017zm-1.1-27.59c-7.11 2.762-10.576 10.617-7.763 17.556 2.82 6.944 10.88 10.347 17.998 7.582 7.107-2.761 10.575-10.62 7.762-17.551C33.64 4.537 25.579 1.133 18.46 3.895z"
                              fillRule="nonzero"
                            />
                          </svg>
                        </i>{" "}
                        <input
                          type="number"
                          name="quantity"
                          id="quantity"
                          onChange={(e: any) => {
                            formik.handleChange(e);
                            formik.setFieldValue(
                              "total_price",
                              e.target.value * data?.price
                            );
                          }}
                          value={formik.values.quantity}
                          autoComplete="name"
                          className="formField__input app-lead-form-name focused"
                        />
                        <label className="formField__label" htmlFor="quantity">
                          Quantité
                        </label>
                      </div>
                    </div>
                    <div className="app-form-control formControl leadForm__fullwidth">
                      <div className="formField app-form-field  formField--outlined">
                        <input
                          type="number"
                          name="total_price"
                          id="total_price"
                          onChange={(e) => {
                            formik.handleChange(e);
                            if (e.target.value.length > 0) {
                              document
                                .getElementById("total_price")
                                ?.classList.add("focused");
                            }
                          }}
                          value={formik.values.total_price}
                          readOnly
                          autoComplete="name"
                          className="formField__input app-lead-form-name focused"
                        />
                        <label
                          className="formField__label"
                          htmlFor="total_price"
                        >
                          Prix total
                        </label>
                      </div>
                    </div>

                    <div className="app-form-control formControl leadForm__fullwidth">
                      <div
                        className="formField app-form-field formField--date formField--outlined"
                        aria-labelledby="modal_date"
                      >
                        <i className="svgIcon svgIcon__calendar formField__icon">
                          <svg viewBox="0 0 38 38">
                            <path
                              d="M2.6 11.4h32.8V6.2h-4v2.4h-6.8V6.2H13.4v2.4H6.6V6.2h-4v5.2zm0 2v22h32.8v-22H2.6zm10.8-9.2h11.2V.6h6.8v3.6h6v33.2H.6V4.2h6V.6h6.8v3.6zM8.6 6.6h2.8v-4H8.6v4zm20.8 0v-4h-2.8v4h2.8z"
                              fillRule="nonzero"
                            />
                          </svg>
                        </i>{" "}
                        <input
                          type="date"
                          name="delivery_date"
                          onChange={(e) => {
                            formik.handleChange(e);
                            if (e.target.value.length > 0) {
                              document
                                .getElementById("delivery_date")
                                ?.classList.add("focused");
                            }
                          }}
                          value={formik.values.delivery_date}
                          id="delivery_date"
                          autoComplete="off"
                          className="formField__input focused app-lead-form-date"
                        />
                        <label
                          className="formField__label"
                          htmlFor="modal_date"
                        >
                          Date de l'évènement
                        </label>
                      </div>
                    </div>
                    <div className="app-form-control formControl leadForm__fullwidth">
                      <div className="app-textarea-field">
                        <div
                          className="formField formField--outlined formField--action app-textarea-edit-button hidden"
                          tabIndex={0}
                        >
                          <i className="svgIcon svgIcon__editPost formField__icon">
                            <svg viewBox="0 0 18 18">
                              <path d="M.522.99h11.839a.522.522 0 01.094 1.035l-.094.009H1.044v14.163h13.703V6.539a.522.522 0 011.036-.094l.008.094v10.18c0 .256-.184.47-.428.514l-.094.008H.522a.522.522 0 01-.514-.428L0 16.719V1.51c0-.256.185-.47.428-.513L.522.989h11.839H.522zM17.456.555c.603.613.766 1.492.175 2.092l-7.234 7.353-.188.123-2.4.903a.522.522 0 01-.675-.667l.888-2.44.118-.188L15.374.38c.597-.607 1.476-.44 2.082.177zM15 2.246L8.964 8.385l-.46 1.265 1.23-.462 6.046-6.148-.78-.792zm1.118-1.134l-.385.39.78.793.373-.379c.12-.122.074-.375-.174-.627-.244-.249-.48-.293-.594-.177z" />
                            </svg>
                          </i>{" "}
                          <div className="formField__input formField__input--unlabeled">
                            <div>Éditez votre message</div>
                          </div>
                        </div>
                        <div
                          className="formField app-textarea-input app-form-field  formField--outlined formField__textarea--outlined"
                          aria-labelledby="modal_message"
                        >
                          <textarea
                            name="message"
                            onChange={(e) => {
                              formik.handleChange(e);
                              if (e.target.value.length > 0) {
                                document
                                  .getElementById("modal_message")
                                  ?.classList.add("focused");
                              }
                            }}
                            id="modal_message"
                            placeholder="Ecrivez un commentaire à l'entreprise"
                            className="formField__input focused app-lead-form-message app-lead-form-comment-field leadForm__message"
                            defaultValue={
                              "Bonjour, je suis intéressé par votre produit. Pouvez-vous me contacter s'il vous plait ?"
                            }
                          />
                          <label className="formField__label">Message </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      data-test-id="modalLeadFormSubmit"
                      className="app-lead-form-submit leadForm__submit button button--block button--primary"
                      aria-label="Télécharger bon"
                    >
                      Commander
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
          <div className="modal__overlay app-modal-overlay" />
        </div>
      )}
    </>
  );
}

import { create } from "zustand";

type DeleteModalType = {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  deleteFn : () => void;
  setFn : (fn : () => void) => void,
  description : string,
  setDescription : (desc : string) => void
};

const useDeleteModal = create<DeleteModalType>((set) => ({
  isOpen: false,
  description : '',
  openModal: () => set({ isOpen: true }),
  closeModal: () => set({ isOpen: false }),
  deleteFn() {
    this.deleteFn()
  },
  setFn(fn) {
    set({ deleteFn : fn})
  },
  setDescription : (desc) => set({ description : desc})
}));

export default useDeleteModal;

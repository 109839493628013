import { create } from "zustand";
import client from "./client";

interface CountryProps {
  countries: any;
  isLoading: boolean;
  getCountries: (params?: any) => any,
}

const useCountry = create<CountryProps>((set) => ({
  countries: [],
  isLoading: true,
  getCountries: async (params?: any) => {
    try {
      const response = await client.countries.getAll(params);
      set({ countries: response?.data, isLoading: false });
      console.log('countries response ---->' , useCountry.getState().countries);
      return response;
    }
    catch (error) {
      console.log('countries errors ---->' , error);
    }
  },
}));

export default useCountry;
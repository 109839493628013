export const API_ENDPOINTS = {
  LOGIN: "/login",
  SOCIAL_LOGIN: "/social-login",
  CHANGE_PASSWORD: "/change-password",
  CHANGE_EMAIL: "/change-email",
  CHANGE_SOCIAL_LOGIN: "/change-social-login",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  REGISTER: "/register",
  LOGOUT: "/logout",
  CURRENT_USER: "/user",
  SERVICE_PROVIDERS: "/service-providers",
  TEAM_MEMBERS: "/service-providers/team-members",
  UPDATE_LOCATION: "/service-providers/update-location",
  UPDATE_LOGO: "/service-providers/update-logo",
  NOTIFICATION_CONFIG: "/service-providers/notification-config",
  UPDATE_MAIL: "/service-providers/update-email",
  SOCIAL_MEDIA_LINKS: "/service-providers/social-media",
  COUNTRIES: "/countries",
  CITIES: "/cities",
  SECTORS: "/sectors",
  ACTIVITIES: "/activities",
  SERVICES: "/services",
  PROMOTIONS: "/promotions",
  OPINIONS: "/opinions",
  QUOTES: "/quotes",
  TEMPLATES_QUOTE: "/quote-templates",
  REPLY_QUOTE: "/reply-quote",
  MEDIAS: "/medias",
  SET_MAIN_MEDIA: "/medias/set-main-media",
  EVENT_TYPES: "/event-types",
  EVENTS: "/events",
  UPDATE_SERVICE_PROVIDER_SERVICES: "/service-providers/update-services",
  UPDATE_SERVICE_PROVIDER_LOCATION: "service-providers/update-location",
  UPDATE_SERVICE_PROVIDER_MIN_PRICE: "service-providers/update-min-price",
  CONTACT_FORM: '/contact-form',
  SERVICE_PROVIDER_CONTACT_FORM: '/service-providers/contact-form',
  WISHLIST: '/wishlist',
  REMOVE_FROM_WISHLIST: '/remove-wishlist',
  CHECK_WISHLIST: 'wishlist/check',
  SERVICE_PROVIDER_EVALUATE: '/service-providers/evaluate',
  ANNOUNCEMENTS: '/announcements',
  ANNOUNCEMENT_ACTIVITY: '/announcements/activity',
  ORDERS: '/orders',
  ASSOCIATE_ACCOUNT: '/associate-account',
  SETTINGS: '/general-settings',
  UPDATE_PROFILE: '/user',
};


import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import useAuth from "data/auth";
import useCity from "data/city";
import useCountry from "data/country";
import { useFormik } from "formik";
import useLoginModal from "hooks/use-login-modal";
import useRegisterModal from "hooks/use-register-modal";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ReactSelect from "react-select";
import Alert from "components/service-provider/admin/partials/alert/warning";

export default function RegisterModal() {
  const { isOpen , closeModal} = useRegisterModal()
  const { openModal : openLoginModal , } = useLoginModal()
  const [alertMessage , setAlertMessage] = useState<any>({
    message: "",
    type: "",
    title : "",
    isDangerousHTML: false
  })
  const { login, socialLogin } = useAuth();
  const { getCountries, countries } = useCountry();
  const { getCities, cities } = useCity();
  const navigate = useNavigate();
  const { register } = useAuth();
  const [cityOptions, setCityOptions] = React.useState<any>([]);
  const [dropdown, setDropdown] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [countryCode, setCountryCode] = React.useState("BF");

  const [currentRole, setCurrentRole] = React.useState("");

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      phone_number: "",
      country_id: "",
      city_id: "",
      city: "",
      wedding_date: "",
      role: "",
      legalTerms: false,
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Le prénom est requis"),
      last_name: Yup.string().required("Le nom est requis"),
      email: Yup.string().required("L'email est requis"),
      password: Yup.string().required("Le mot de passe est requis"),
      phone_number: Yup.string().required("Le téléphone est requis"),
      // wedding_date: Yup.string().required("La date de mariage est requise"),
      role: Yup.string().required("Le rôle est requis"),
      legalTerms: Yup.boolean().required("Les conditions sont requises"),
    }),
    onSubmit: async (values) => {
      if (!values.country_id || !values.city_id || values.legalTerms === false) {
         if (!values.country_id) {
        formik.setFieldError("country_id", "Le pays est requis");
      }
      if (!values.city_id) {
        formik.setFieldError("city_id", "La ville est requise");
      }
      if (values.legalTerms == false) {
        formik.setFieldError("legalTerms", "Les conditions sont requises");
      }
      }
      let data = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        password: values.password,
        phone_number: values.phone_number,
        city_id: values.city_id,
        wedding_date: values.wedding_date,
        role: values.role === "1" ? "bride" : values.role === "2" ? "groom" : "user",
      };
      const res = await register(data, formik);
      if (res?.success) {
        window.location.reload();
      }
      if (res.response?.status === 401) {
        setAlertMessage({
          message: 'Email ou mot de passe incorrect',
          type: 'warning',
          title : 'Attention',
          isDangerousHTML : false
        })
        return;
      }
      if (res.response?.status === 422) {
        const errors = res.response?.data?.errors;
        let messages = "";
        for (const key in errors) {
          messages += errors[key] + "<br />";
        }
        setAlertMessage({
          message: messages,
          type: 'error',
          title : 'Attention',
          isDangerousHTML : true
        })
        return;
      }
      if (res.response?.status === 500) {
        setAlertMessage({
          message: 'Une erreur est survenue, veuillez réessayer',
          type: 'error',
          title : 'Attention',
          isDangerousHTML : false
        })
        return;
      }

    },
  });

  React.useEffect(() => {
    getCountries();
    getCities({ country_code: countryCode });
  }, [countryCode]);

  const countryOptions = countries.map((country: any) => {
    return {
      label: country.name,
      value: country.id,
      code: country.code,
      cities: country.cities,
    };
  });
  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (res) => {
      await axios
        .get("https://www.googleapis.com/oauth2/v3/userinfo", {
          headers: {
            Authorization: `Bearer ${res.access_token}`,
          },
        })
        .then(async (res) => {
          let data = {
            email: res.data.email,
            first_name: res.data.family_name,
            last_name: res.data.given_name,
            full_name: res.data.name,
            password: res.data.sub,
            avatar: res.data.picture,
            social_id: res.data.sub,
            social_type: "google",
          };
          const response = await socialLogin(data);
          if (response?.success) {
            navigate("/");
          }
          console.log("res", res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    flow: "implicit",
  });

  const responseFacebook = async (response: any) => {
    console.log("response", response);
    let data = {
      email: response.email,
      first_name: response.first_name,
      last_name: response.last_name,
      full_name: response.name,
      password: response.id,
      avatar: response.picture.data.url,
      social_id: response.id,
      social_type: "facebook",
    };
    const res = await socialLogin(data);
    if (res?.success) {
      navigate("/");
    }
  };

  const customStyles = {
    control: (base: any) => ({
      ...base,
      height: "50px",
      borderRadius: 0,
      borderColor: "#e2e2e2",
      "&:hover": {
        borderColor: "#e2e2e2",
      },
    }),
  };


  return (
    <>
    {
      isOpen ? (
        <div
        className="modalExpanded signupModal app-auth-modal-form"
        role="dialog"
      >
        <div className="modalExpanded__content">
          <section className="app-auth-form authModal">
            <div className="authModal__header">
              <p className="authModal__title app-auth-title">Inscription</p>
              <i className="svgIcon svgIcon__close authModal__closeIcon app-modal-close" onClick={closeModal}>

                <svg viewBox="0 0 26 26">
                  <path
                    d="M12.983 10.862L23.405.439l2.122 2.122-10.423 10.422 10.423 10.422-2.122 2.122-10.422-10.423L2.561 25.527.439 23.405l10.423-10.422L.439 2.561 2.561.439l10.422 10.423z"
                    fillRule="nonzero"
                  />
                </svg>
              </i>{" "}
            </div>
            <div className="authModal__content">
            <div className="socialButtons app-auth-social-area-buttons">
                  <FacebookLogin
                    appId="887897829544510"
                    autoLoad={false}
                    callback={responseFacebook}
                    fields="name, email, picture,first_name,last_name"
                    scope="public_profile,email"
                    render={(renderProps: any) => (
                      <button
                      onClick={renderProps.onClick}
                      className="socialButtons--facebook btnOutline btnOutline--grey app-facebook-button mb10 mr15 btnOutline--full                 "
                      type="button"
                      style={{ position: "relative" , justifyContent: "center"}}
                    >
                     <div>
                     <i className="svgIcon svgIcon__facebook svgIcon--medium fleft"
                      style={{
                        marginLeft : "50px"
                      }}
                     >
                        <svg
                          width={12}
                          height={24}
                          viewBox="0 0 12 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.81 3.985H12.001V0.169C11.623 0.117 10.323 0 8.809 0C5.65 0 3.486 1.987 3.486 5.639V9H0V13.266H3.486V24H7.76V13.267H11.105L11.636 9.001H7.759V6.062C7.76 4.829 8.092 3.985 9.81 3.985V3.985Z"
                            fill="#335795"
                          />
                        </svg>
                      </i>{" "}
                      <hr 
                      style={{
                        width: "1px",
                        height: "20px",
                        backgroundColor: "#ccc",
                        margin: "0 10px",
                        position : "absolute",
                        top: "20%",
                        left: "20%"
                      }}
                      />
                     </div>
                      <p  style={{
                        marginRight : "55px"
                      }}>
                      <b>Poursuivre avec Facebook</b>
                      </p>
                    </button>
                    )}
                  />

<div
                  className="socialButtons--googleWrapper mb10"
                  onClick={() => handleGoogleLogin()}
                  style={{ position: "relative" , display: "flex" , justifyContent: "center"}}
                >
                  <span className="socialButtons--googleTopButton socialButtons--google btnOutline btnOutline--grey btnOutline--full">
                    <i className="svgIcon svgIcon__google svgIcon--medium fleft" style={{
                        marginLeft : "50px"
                      }}>
                      <svg
                        width={20}
                        height={20}
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.6875 10C4.6875 9.00913 4.96051 8.08088 5.4348 7.28608V3.91467H2.0634C0.725313 5.65249 0 7.77042 0 10C0 12.2296 0.725313 14.3476 2.0634 16.0854H5.4348V12.714C4.96051 11.9192 4.6875 10.9909 4.6875 10V10Z"
                          fill="#FBBD00"
                        />
                        <path
                          d="M10 15.3126L7.65625 17.6563L10 20.0001C12.2296 20.0001 14.3475 19.2747 16.0854 17.9367V14.5688H12.7175C11.9158 15.0449 10.9836 15.3126 10 15.3126V15.3126Z"
                          fill="#0F9D58"
                        />
                        <path
                          d="M5.43488 12.714L2.06348 16.0854C2.3284 16.4295 2.61688 16.759 2.92902 17.0711C4.81777 18.9598 7.32898 20 10.0001 20V15.3125C8.06164 15.3125 6.3627 14.2688 5.43488 12.714Z"
                          fill="#31AA52"
                        />
                        <path
                          d="M20 10C20 9.39164 19.9449 8.78211 19.8363 8.18844L19.7483 7.70801H10V12.3955H14.7442C14.2835 13.312 13.5752 14.0596 12.7175 14.5689L16.0853 17.9367C16.4294 17.6718 16.7589 17.3833 17.0711 17.0711C18.9598 15.1823 20 12.6711 20 10V10Z"
                          fill="#3C79E6"
                        />
                        <path
                          d="M13.7565 6.24348L14.1708 6.65777L17.4854 3.34324L17.0711 2.92895C15.1823 1.0402 12.6711 0 10 0L7.65625 2.34375L10 4.6875C11.419 4.6875 12.7531 5.24008 13.7565 6.24348Z"
                          fill="#CF2D48"
                        />
                        <path
                          d="M10.0001 4.6875V0C7.32902 0 4.81781 1.0402 2.92902 2.92891C2.61688 3.24105 2.3284 3.57055 2.06348 3.91465L5.43488 7.28605C6.36273 5.73125 8.06168 4.6875 10.0001 4.6875V4.6875Z"
                          fill="#EB4132"
                        />
                      </svg>
                    </i>{" "}
                    <hr 
                      style={{
                        width: "1px",
                        height: "20px",
                        backgroundColor: "#ccc",
                        margin: "0 10px",
                        position : "absolute",
                        top: "20%",
                        left: "20%"
                      }}
                      />
                   <p  style={{
                        marginRight : "70px"
                      }}>
                   <b>Poursuivre avec Google</b>
                   </p>
                  </span>
                  <div className="app-google-login-button socialButtons--googleHidden btnOutline--full app-google-login"></div>
                </div>
                </div>
              <p className="authModal__subtitle app-auth-subtitle">
                Ou inscrivez-vous avec votre email
              </p>
              {
                alertMessage.message && (
                  <Alert {...alertMessage} />
                )
              }
              <form
                className="authModalForm app-auth-form-signup"
                method="post"
                onSubmit={(e) => {
                  e.preventDefault();
                  formik.handleSubmit();
                }}
              >
                
                <div className="app-auth-social-area-photo authModalForm__fullwidth" />
                <div className={"app-form-control formControl authModalForm__input authModalForm__fullwidth " + (formik.errors.first_name && formik.touched.first_name ? 'formControl--error' : '')}>
                  <div
                    className="formField app-form-field  formField--outlined"
                    aria-labelledby="modalSignup_name"
                  >
                    <i className="svgIcon svgIcon__user formField__icon">
                      <svg viewBox="0 0 47 53">
                        <path
                          d="M31.722 29.786C40.49 32.99 46.78 42.236 46.78 51.962v1H.846v-1c0-9.615 6.156-18.77 14.774-22.069-2.971-1.686-5.407-4.324-6.774-7.691-3.236-7.981.75-17.009 8.891-20.172 8.13-3.154 17.343.736 20.575 8.698 2.915 7.181-.03 15.22-6.59 19.058zm-12.16 1.698l-2.469.006c-7.922 2.633-13.8 10.765-14.222 19.472h41.885c-.423-8.744-6.343-16.9-14.309-19.501l-2.786.006a16.09 16.09 0 01-8.099.017zm-1.1-27.59c-7.11 2.762-10.576 10.617-7.763 17.556 2.82 6.944 10.88 10.347 17.998 7.582 7.107-2.761 10.575-10.62 7.762-17.551C33.64 4.537 25.579 1.133 18.46 3.895z"
                          fillRule="nonzero"
                        />
                      </svg>
                    </i>{" "}
                    <input
                      type="text"
                      name="first_name"
                      onChange={(e) => {
                        formik.handleChange(e);
                        if (e.target.value.length > 0) {
                          document.getElementById('modalSignup_name')?.classList.add('focused')
                        }
                      }}
                      id="modalSignup_name"
                      className="formField__input app-auth-signup-name"
                    />
                    <label
                      className="formField__label"
                      htmlFor="modalSignup_name"
                    >
                       Nom
                    </label>
                  </div>
                  {
                    formik.errors.first_name && formik.touched.first_name ? (
                      <div className="formControl__hint">
                        {formik.errors.first_name}
                      </div>
                    ) : null
                  }
                </div>
                <div className={"app-form-control formControl authModalForm__input authModalForm__fullwidth " + (formik.errors.last_name && formik.touched.last_name ? 'formControl--error' : '')}>
                  <div
                    className="formField app-form-field  formField--outlined"
                    aria-labelledby="modalSignup_name"
                  >
                    <i className="svgIcon svgIcon__user formField__icon">
                      <svg viewBox="0 0 47 53">
                        <path
                          d="M31.722 29.786C40.49 32.99 46.78 42.236 46.78 51.962v1H.846v-1c0-9.615 6.156-18.77 14.774-22.069-2.971-1.686-5.407-4.324-6.774-7.691-3.236-7.981.75-17.009 8.891-20.172 8.13-3.154 17.343.736 20.575 8.698 2.915 7.181-.03 15.22-6.59 19.058zm-12.16 1.698l-2.469.006c-7.922 2.633-13.8 10.765-14.222 19.472h41.885c-.423-8.744-6.343-16.9-14.309-19.501l-2.786.006a16.09 16.09 0 01-8.099.017zm-1.1-27.59c-7.11 2.762-10.576 10.617-7.763 17.556 2.82 6.944 10.88 10.347 17.998 7.582 7.107-2.761 10.575-10.62 7.762-17.551C33.64 4.537 25.579 1.133 18.46 3.895z"
                          fillRule="nonzero"
                        />
                      </svg>
                    </i>{" "}
                    <input
                      type="text"
                      name="last_name"
                      onChange={(e) => {
                        formik.handleChange(e);
                        if (e.target.value.length > 0) {
                          document.getElementById('modalSignup_name2')?.classList.add('focused')
                        }
                      }}
                      id="modalSignup_name2"
                      autoComplete="name"
                      className="formField__input app-auth-signup-name"
                    />
                    <label
                      className="formField__label"
                      htmlFor="modalSignup_name"
                    >
                      Prénom
                    </label>
                  </div>
                  {
                    formik.errors.last_name && formik.touched.last_name ? (
                      <div className="formControl__hint">
                        {formik.errors.last_name}
                      </div>
                    ) : null
                  }
                </div>
                <div className={"app-form-control formControl authModalForm__input authModalForm__fullwidth" + (formik.errors.email && formik.touched.email ? ' formControl--error' : '')}>
                  <div
                    className="formField app-form-field  formField--outlined"
                    aria-labelledby="modalSignup_email"
                  >
                    <i className="svgIcon svgIcon__envelope formField__icon">
                      <svg viewBox="0 0 20 15">
                        <path
                          d="M18.912 12.604V1.995c0-.47-.406-.911-.8-.911H1.888c-.393 0-.799.441-.799.911v10.609c0 .466.402.896.8.896h16.225c.397 0 .799-.43.799-.896zm1 0c0 1.003-.834 1.896-1.8 1.896H1.888c-.965 0-1.799-.893-1.799-1.896V1.995C.088.991.923.084 1.888.084h16.225c.964 0 1.799.907 1.799 1.911v10.609zm-9.631-4.71a.5.5 0 01-.562.827L2.513 3.828a.5.5 0 01.562-.827l7.206 4.893zm7.18 1.85a.5.5 0 01-.552.834l-3.29-2.175a.5.5 0 11.552-.834l3.29 2.175zm-14.37.834a.5.5 0 01-.552-.834l3.29-2.175a.5.5 0 11.551.834l-3.29 2.175zm7.185-1.859a.5.5 0 11-.562-.827l7.206-4.893a.5.5 0 01.562.827l-7.206 4.893z"
                          fillRule="nonzero"
                        />
                      </svg>
                    </i>{" "}
                    <input
                      type="email"
                      name="email"
                      onChange={(e) => {
                        formik.handleChange(e);
                        if (e.target.value.length > 0) {
                          document.getElementById('modalSignup_email')?.classList.add('focused')
                        }
                      }}
                      id="modalSignup_email"
                      autoComplete="email"
                      className="formField__input app-auth-signup-email"
                    />
                    <label
                      className="formField__label"
                      htmlFor="modalSignup_email"
                    >
                      Email
                    </label>
                  </div>
                  {
                    formik.errors.email && formik.touched.email ? (
                      <div className="formControl__hint">
                        {formik.errors.email}
                      </div>
                    ) : null
                  }
                </div>
                <div className={"app-form-control formControl authModalForm__input authModalForm__fullwidth " + (formik.errors.password && formik.touched.password ? 'formControl--error' : '')}>
                  <div
                    className="formField app-form-field  formField--outlined"
                    aria-labelledby="modalSignup_password"
                  >
                    <i className="svgIcon svgIcon__lock formField__icon">
                      <svg viewBox="0 0 18 18">
                        <path d="M8.99 0c2.336 0 4.546 2.025 4.546 4.312v3.665a6.094 6.094 0 011.444 3.953c0 3.351-2.68 6.07-5.99 6.07S3 15.281 3 11.93c0-1.51.544-2.89 1.444-3.953V4.312C4.444 2.025 6.654 0 8.99 0zm0 6.865c-2.752 0-4.985 2.266-4.985 5.065 0 2.8 2.233 5.065 4.985 5.065 2.752 0 4.986-2.266 4.986-5.065 0-2.799-2.234-5.065-4.986-5.065zm0 4.27a.796.796 0 01.503 1.41v2.023a.502.502 0 01-.997.09l-.008-.09v-2.024a.796.796 0 01.502-1.409zm0-10.13c-1.8 0-3.541 1.595-3.541 3.307v2.723A5.911 5.911 0 018.99 5.86c1.326 0 2.55.436 3.542 1.174V4.312c0-1.712-1.742-3.307-3.542-3.307z" />
                      </svg>
                    </i>{" "}
                    <input
                      type="password"
                      name="password"
                      id="modalSignup_password"
                      onChange={(e) => {
                        formik.handleChange(e);
                        if (e.target.value.length > 0) {
                          document.getElementById('modalSignup_password')?.classList.add('focused')
                        }
                      }}
                      autoComplete="new-password"
                      className="formField__input app-auth-signup-password"
                    />
                    <label
                      className="formField__label"
                      htmlFor="modalSignup_password"
                    >
                      Mot de passe
                    </label>
                  </div>
                  {
                    formik.errors.password && formik.touched.password ? (
                      <div className="formControl__hint">
                        {formik.errors.password}
                      </div>
                    ) : null
                  }
                </div>
                <div className={"app-form-control formControl authModalForm__fullwidth authModalForm__rol " + (formik.errors.role && formik.touched.role ? 'formControl--error' : '')}>
                  <div
                    className="app-form-field toggleButton"
                    role="group"
                    aria-labelledby="modalSignup_rol"
                    aria-label="Indique-nous ton rôle dans ce mariage"
                  >
                    <input
                      type="radio"
                      name="role"
                      id="modalSignup_rol1"
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      value={"1"}
                      className="app-auth-signup-rol focused"
                    />
                    <label htmlFor="modalSignup_rol1">Mariée </label>
                    <input
                      type="radio"
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      name="role"
                      id="modalSignup_rol2"
                      value={"2"}
                      className="app-auth-signup-rol focused"
                    />
                    <label htmlFor="modalSignup_rol2">Marié </label>
                    <input
                      type="radio"
                      name="role"
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      value={"3"}
                      id="modalSignup_rol8"
                      className="app-auth-signup-rol focused"
                    />
                    <label htmlFor="modalSignup_rol8">Autres </label>
                  </div>
                  {
                    formik.errors.role && formik.touched.role ? (
                      <div className="formControl__hint">
                        {formik.errors.role}
                      </div>
                    ) : null
                  }
                </div>
                
                <div className={"app-form-control formControl authModalForm__input" + (formik.errors.phone_number && formik.touched.phone_number ? ' formControl--error' : '')}>
                  <div
                    className="formField app-form-field  formField--outlined"
                    aria-labelledby="modalSignup_country"
                  >
                    <i className="svgIcon svgIcon__angleDownBlood formField__icon">
                      <svg viewBox="0 0 19 11">
                        <path
                          d="M9.72 10.068a.773.773 0 01-.586-.334L1.011 1.608A.776.776 0 012.02.436l.087.075 7.676 7.676L17.459.51l.087-.075a.776.776 0 011.01 1.172l-8.123 8.126a.78.78 0 01-.585.334z"
                          fillRule="nonzero"
                        />
                      </svg>
                    </i>{" "}
                    <ReactSelect
                      className=""
                      onChange={(e : any) => {
                        formik.setFieldValue("country_id", e.value);
                        setCountryCode(e.code);
                        getCities({ country_code: e.code });
                      }}
                      options={countryOptions}
                      placeholder="Pays"
                      styles={customStyles}
                      
                    />
                   
                  </div>
                  {
                    formik.errors.country_id && formik.touched.country_id ? (
                      <div className="formControl__hint">
                        {formik.errors.country_id}
                      </div>
                    ) : null
                  }
                </div>
                <div className={"app-form-control formControl authModalForm__input " + (formik.errors.city_id && formik.touched.city_id ? 'formControl--error' : '')}>
                  <div
                    className="formField app-form-field  formField--outlined"
                    aria-labelledby="modalSignup_country"
                  >
                    <i className="svgIcon svgIcon__angleDownBlood formField__icon">
                      <svg viewBox="0 0 19 11">
                        <path
                          d="M9.72 10.068a.773.773 0 01-.586-.334L1.011 1.608A.776.776 0 012.02.436l.087.075 7.676 7.676L17.459.51l.087-.075a.776.776 0 011.01 1.172l-8.123 8.126a.78.78 0 01-.585.334z"
                          fillRule="nonzero"
                        />
                      </svg>
                    </i>{" "}
                    <ReactSelect
                      className=""
                      onChange={(e : any) => {
                        formik.setFieldValue("city_id", e.value);
                      }}
                      options={cities?.map((city: any) => {
                        return {
                          label: city.name,
                          value: city.id,
                        };
                      })}
                      placeholder="Ville"
                      styles={customStyles}
                      
                    />
                   
                  </div>
                  {
                    formik.errors.city_id && formik.touched.city_id ? (
                      <div className="formControl__hint">
                        {formik.errors.city_id}
                      </div>
                    ) : null
                  }
                </div>
                <div className={"app-form-control formControl authModalForm__input" + (formik.errors.wedding_date && formik.touched.wedding_date ? ' formControl--error' : '')}>
                  <div
                    className="formField app-form-field  formField--outlined"
                    aria-labelledby="modalSignup_date"
                  >
                    <i className="svgIcon svgIcon__calendar formField__icon">
                      <svg viewBox="0 0 38 38">
                        <path
                          d="M2.6 11.4h32.8V6.2h-4v2.4h-6.8V6.2H13.4v2.4H6.6V6.2h-4v5.2zm0 2v22h32.8v-22H2.6zm10.8-9.2h11.2V.6h6.8v3.6h6v33.2H.6V4.2h6V.6h6.8v3.6zM8.6 6.6h2.8v-4H8.6v4zm20.8 0v-4h-2.8v4h2.8z"
                          fillRule="nonzero"
                        />
                      </svg>
                    </i>{" "}
                    <input
                      type="date"
                      name="wedding_date"
                      id="modalSignup_date"
                      onChange={(e) => {
                        formik.handleChange(e);
                        if (e.target.value.length > 0) {
                          document.getElementById('modalSignup_date')?.classList.add('focused')
                        }
                      }}
                      placeholder="JJ/MM/AAAA"
                      autoComplete="off"
                      className="formField__input focused app-auth-signup-date"
                    />
                    <label
                      className="formField__label"
                      htmlFor="modalSignup_date"
                    >
                      Mariage le
                    </label>
                  </div>
                  {
                    formik.errors.wedding_date && formik.touched.wedding_date ? (
                      <div className="formControl__hint">
                        {formik.errors.wedding_date}
                      </div>
                    ) : null
                  }
                </div>
                <div className={"app-form-control formControl authModalForm__input authModalForm__inputPhone" + (formik.errors.phone_number && formik.touched.phone_number ? ' formControl--error' : '')}>
                  <div
                    className="formField app-form-field  formField--outlined"
                    aria-labelledby="modalSignup_phone"
                  >
                    <i className="svgIcon svgIcon__phone formField__icon">
                      <svg viewBox="0 0 32 32">
                        <path
                          d="M12.232 10.97a73.923 73.923 0 008.8 8.8l.464-.466a4.144 4.144 0 015.864 0l3.335 3.336a4.15 4.15 0 010 5.863l-1.83 1.829a5.718 5.718 0 01-7.192.719C13.528 25.667 6.34 18.48.95 10.329a5.711 5.711 0 01.72-7.192L3.5 1.31a4.148 4.148 0 015.866-.005l3.33 3.335a4.144 4.144 0 01.003 5.862l-.467.468zm-.949-1.88c.838-.838.839-2.2 0-3.034L7.954 2.72a2.148 2.148 0 00-3.038.003l-1.83 1.829a3.71 3.71 0 00-.465 4.675c5.24 7.925 12.235 14.92 20.156 20.156a3.718 3.718 0 004.675-.466l1.829-1.827c.837-.84.837-2.2.001-3.036l-3.335-3.336a2.144 2.144 0 00-3.035 0l-1.109 1.11a1 1 0 01-1.341.068 75.554 75.554 0 01-10.354-10.353 1 1 0 01.065-1.34l1.111-1.114z"
                          fillRule="nonzero"
                        />
                      </svg>
                    </i>{" "}
                    <input
                      type="tel"
                      name="phone_number"
                      onChange={(e) => {
                        formik.handleChange(e);
                        if (e.target.value.length > 0) {
                          document.getElementById('modalSignup_phone')?.classList.add('focused')
                        }
                      }}
                      id="modalSignup_phone"
                      autoComplete="tel"
                      className="formField__input app-auth-signup-phone"
                    />
                    <label
                      className="formField__label"
                      htmlFor="modalSignup_phone"
                    >
                      Téléphone{" "}
                    </label>
                  </div>
                  {
                    formik.errors.phone_number && formik.touched.phone_number ? (
                      <div className="formControl__hint">
                        {formik.errors.phone_number}
                      </div>
                    ) : null
                  }
                </div>
                <div className={"app-form-control formControl authModalForm__input authModalForm__fullwidth authModalForm__legal" + (formik.errors.legalTerms && formik.touched.legalTerms ? ' formControl--error' : '')}>
                  <div
                    className="checkbox app-form-field"
                    aria-labelledby="modalSignup_legalTerms"
                  >
                    <input
                      type="checkbox"
                      name="legalTerms"
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      id="modalSignup_legalTerms"
                      className="checkbox__input app-auth-signup-legal"
                    />
                    <label
                      className="checkbox__icon"
                      htmlFor="modalSignup_legalTerms"
                    >
                      J'accepte les{" "}
                      <Link
                        to="/legal-terms"
                      >
                        conditions d'utilisation
                      </Link>{" "}
                      et de{" "}
                      <Link
                        to="/privacy-policy"
                      >
                        Confidentialité
                      </Link>
                      .
                    </label>
                  </div>
                  {
                    formik.errors.legalTerms && formik.touched.legalTerms ? (
                      <div className="formControl__hint">
                        {formik.errors.legalTerms}
                      </div>
                    ) : null
                  }
                </div>
                <div className="app-captcha js-recaptcha--hidden formControl--error"></div>{" "}
                <div className="authModalForm__submit">
                  <button
                    className="button button--primary button--block app-auth-signup-submit"
                    type="submit"
                    style={{ width: "100%" }}
                  >
                    M'inscrire
                  </button>
                </div>
              </form>
              <p className="authModal__phrase">
                Déjà inscrit(e) ?{" "}
                <span
                  role="button"
                  className="authModal__link app-auth-signup-switch-login"
                  onClick={() => {
                    closeModal();
                    openLoginModal();
                  }}
                >
                  Accédez
                </span>
              </p>
            </div>
          </section>
        </div>
        <div className="modalExpanded__overlay app-modal-overlay" />
      </div>
      ) : null
    }
    </>
  );
}

import { create } from "zustand";
import client from "./client";
import { useQuery } from "react-query";
import { API_ENDPOINTS } from "./client/endpoints";

interface CityProps {
  cities: any;
  isLoading: boolean;
  getCities: (params ?: any) => Promise<any>;
  getCity: (id: string) => Promise<any>;
}

const useCity = create<CityProps>((set) => ({
  cities: [],
  isLoading: true,
  getCities: async (params) => {
    try {
      const response = await client.cities.getAll(params);
      set({ cities: response?.data, isLoading: false });
      console.log('cities response ---->' , useCity.getState().cities);
      return response;
    }
    catch (error) {
      console.log('cities errors ---->' , error);
    }
  },
  getCity: async (id: string) => {
    const response = await fetch(`http://localhost:3000/cities/${id}`);
    const data = await response.json();
    set({ cities: data, isLoading: false });
  },
}));

export default useCity;

export function useCityStore(params: any) {
    const cityQuery = useQuery<any>([API_ENDPOINTS.CITIES, params], () => useCity.getState().getCities(params));

    return {
        cities: cityQuery.data,
        isLoading: cityQuery.isLoading,
        error: cityQuery.error,
    };
}
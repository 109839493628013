import { useFormik } from "formik";
import useLoginModal from "hooks/use-login-modal";
import useRegisterModal from "hooks/use-register-modal";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import useAuth from "data/auth";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// import "../../../App.css";
import Alert from "components/service-provider/admin/partials/alert/warning";
import usePromotionModal from "hooks/use-promotion-modal";
import useProfile from "data/profile";
import classNames from "classnames";

export default function PromotionModal() {
  const { isOpen, closeModal , data } = usePromotionModal();
  const { openModal: openRegisterModal } = useRegisterModal();
  const [alertmessage, setAlerMessage] = useState<any>({
    message: "",
    type: "",
    title: "",
    isDangerousHTML: false,
  });
  const { userProfile } = useProfile()

  const formik = useFormik({
    initialValues: {
      promotion_id: data?.id,
      first_name: userProfile ? userProfile.first_name : "",
      last_name: userProfile ? userProfile.last_name : "",
      email: userProfile ? userProfile.email : "",
      phone: userProfile ? userProfile.phone : "",
      event_date: "",
      message: "",
    },
    validationSchema: Yup.object({}),
    onSubmit: async (values) => {
     console.log(values)
    },
  });
  return (
    <>
      {isOpen && (
        <div className="modal " role="dialog" aria-modal="true">
          <div className="modal__content app-lead-form-modal-content">
            <section className="leadFormModal leadFormModal--outlined">
              <div className="leadFormModal__header">
                <div>
                  <p className="leadFormModal__subtitle">
                    {data?.service_provider_name}
                  </p>
                  <h1 className="leadFormModal__title">Télécharger bon</h1>
                </div>
                <i className="svgIcon svgIcon__close leadFormModal__closeIcon app-modal-close" onClick={closeModal}>
                  <svg viewBox="0 0 26 26">
                    <path
                      d="M12.983 10.862L23.405.439l2.122 2.122-10.423 10.422 10.423 10.422-2.122 2.122-10.422-10.423L2.561 25.527.439 23.405l10.423-10.422L.439 2.561 2.561.439l10.422 10.423z"
                      fillRule="nonzero"
                    />
                  </svg>
                </i>{" "}
              </div>
              <div className="leadFormModal__content">
                <p className="leadFormModal__description">
                  Remplissez le formulaire suivant pour télécharger votre bon.
                  L'entreprise Studio Autres Regards vous contactera pour vous
                  informer au sujet de cette promotion. Toutes vos informations
                  seront traitées selon notre politique de confidentialité.{" "}
                </p>
                <form
                  method="POST"
                  className="leadForm leadFormAside__form--outlined dealLeadFormModal__form app-deals-bf-lead app-internal-tracking-form"
                  onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit(e)
                  }}
                >
                  <div className="leadForm__content app-lead-form-content leadForm__content--visibleScroll">
                    <div className="app-form-control formControl">
                      <div className="formField app-form-field  formField--outlined">
                        <i className="svgIcon svgIcon__user formField__icon">
                          <svg viewBox="0 0 47 53">
                            <path
                              d="M31.722 29.786C40.49 32.99 46.78 42.236 46.78 51.962v1H.846v-1c0-9.615 6.156-18.77 14.774-22.069-2.971-1.686-5.407-4.324-6.774-7.691-3.236-7.981.75-17.009 8.891-20.172 8.13-3.154 17.343.736 20.575 8.698 2.915 7.181-.03 15.22-6.59 19.058zm-12.16 1.698l-2.469.006c-7.922 2.633-13.8 10.765-14.222 19.472h41.885c-.423-8.744-6.343-16.9-14.309-19.501l-2.786.006a16.09 16.09 0 01-8.099.017zm-1.1-27.59c-7.11 2.762-10.576 10.617-7.763 17.556 2.82 6.944 10.88 10.347 17.998 7.582 7.107-2.761 10.575-10.62 7.762-17.551C33.64 4.537 25.579 1.133 18.46 3.895z"
                              fillRule="nonzero"
                            />
                          </svg>
                        </i>{" "}
                        <input
                          type="text"
                          name="last_name"
                          id="last_name"
                          onChange={(e) => {
                            formik.handleChange(e);
                            if (e.target.value.length > 0) {
                              document
                                .getElementById("last_name")
                                ?.classList.add("focused");
                            }}}
                          value={formik.values.last_name}
                          autoComplete="name"
                          className="formField__input app-lead-form-name"
                        />
                        <label className="formField__label" htmlFor="last_name">
                          Nom
                        </label>
                      </div>
                    </div>
                    <div className="app-form-control formControl">
                      <div
                        className="formField app-form-field  formField--outlined"
                        aria-labelledby="modal_name"
                      >
                        <i className="svgIcon svgIcon__user formField__icon">
                          <svg viewBox="0 0 47 53">
                            <path
                              d="M31.722 29.786C40.49 32.99 46.78 42.236 46.78 51.962v1H.846v-1c0-9.615 6.156-18.77 14.774-22.069-2.971-1.686-5.407-4.324-6.774-7.691-3.236-7.981.75-17.009 8.891-20.172 8.13-3.154 17.343.736 20.575 8.698 2.915 7.181-.03 15.22-6.59 19.058zm-12.16 1.698l-2.469.006c-7.922 2.633-13.8 10.765-14.222 19.472h41.885c-.423-8.744-6.343-16.9-14.309-19.501l-2.786.006a16.09 16.09 0 01-8.099.017zm-1.1-27.59c-7.11 2.762-10.576 10.617-7.763 17.556 2.82 6.944 10.88 10.347 17.998 7.582 7.107-2.761 10.575-10.62 7.762-17.551C33.64 4.537 25.579 1.133 18.46 3.895z"
                              fillRule="nonzero"
                            />
                          </svg>
                        </i>{" "}
                        <input
                          type="text"
                          name="first_name"
                          id="first_name"
                          onChange={(e) => {
                            formik.handleChange(e);
                            if (e.target.value.length > 0) {
                              document
                                .getElementById("first_name")
                                ?.classList.add("focused");
                            }}}
                          value={formik.values.first_name}
                          data-test-id="modalLeadFormName"
                          autoComplete="name"
                          className={classNames("formField__input  app-lead-form-name" , {
                            'focused' : userProfile ? true : false,
                          })}
                        />
                        <label
                          className="formField__label"
                          htmlFor="first_name"
                        >
                          Prénom
                        </label>
                      </div>
                    </div>
                    <div className="app-form-control formControl ">
                      <div
                        className="formField app-form-field  formField--readonly formField--outlined"
                        aria-labelledby="modal_email"
                      >
                        <i className="svgIcon svgIcon__envelope formField__icon">
                          <svg viewBox="0 0 20 15">
                            <path
                              d="M18.912 12.604V1.995c0-.47-.406-.911-.8-.911H1.888c-.393 0-.799.441-.799.911v10.609c0 .466.402.896.8.896h16.225c.397 0 .799-.43.799-.896zm1 0c0 1.003-.834 1.896-1.8 1.896H1.888c-.965 0-1.799-.893-1.799-1.896V1.995C.088.991.923.084 1.888.084h16.225c.964 0 1.799.907 1.799 1.911v10.609zm-9.631-4.71a.5.5 0 01-.562.827L2.513 3.828a.5.5 0 01.562-.827l7.206 4.893zm7.18 1.85a.5.5 0 01-.552.834l-3.29-2.175a.5.5 0 11.552-.834l3.29 2.175zm-14.37.834a.5.5 0 01-.552-.834l3.29-2.175a.5.5 0 11.551.834l-3.29 2.175zm7.185-1.859a.5.5 0 11-.562-.827l7.206-4.893a.5.5 0 01.562.827l-7.206 4.893z"
                              fillRule="nonzero"
                            />
                          </svg>
                        </i>{" "}
                        <input
                          type="email"
                          name="Mail"
                          id="modal_email"
                          autoComplete="email"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          readOnly={userProfile ? true : false}
                          className={classNames("formField__input app-lead-form-email" , {
                            'focused' : userProfile ? true : false,
                          })}
                        />
                        <label
                          className="formField__label"
                          htmlFor="modal_email"
                        >
                          Email
                        </label>
                      </div>
                    </div>
                    <div className="app-form-control formControl ">
                      <div
                        className="formField app-form-field  formField--outlined"
                        aria-labelledby="modal_phone"
                      >
                        <i className="svgIcon svgIcon__phone formField__icon">
                          <svg viewBox="0 0 32 32">
                            <path
                              d="M12.232 10.97a73.923 73.923 0 008.8 8.8l.464-.466a4.144 4.144 0 015.864 0l3.335 3.336a4.15 4.15 0 010 5.863l-1.83 1.829a5.718 5.718 0 01-7.192.719C13.528 25.667 6.34 18.48.95 10.329a5.711 5.711 0 01.72-7.192L3.5 1.31a4.148 4.148 0 015.866-.005l3.33 3.335a4.144 4.144 0 01.003 5.862l-.467.468zm-.949-1.88c.838-.838.839-2.2 0-3.034L7.954 2.72a2.148 2.148 0 00-3.038.003l-1.83 1.829a3.71 3.71 0 00-.465 4.675c5.24 7.925 12.235 14.92 20.156 20.156a3.718 3.718 0 004.675-.466l1.829-1.827c.837-.84.837-2.2.001-3.036l-3.335-3.336a2.144 2.144 0 00-3.035 0l-1.109 1.11a1 1 0 01-1.341.068 75.554 75.554 0 01-10.354-10.353 1 1 0 01.065-1.34l1.111-1.114z"
                              fillRule="nonzero"
                            />
                          </svg>
                        </i>{" "}
                        <input
                          type="tel"
                          name="phone"
                          value={formik.values.phone}
                          onChange={(e) => {
                            formik.handleChange(e);
                            if (e.target.value.length > 0) {
                              document
                                .getElementById("modal_phone")
                                ?.classList.add("focused");
                            }}}
                          id="modal_phone"
                          data-test-id="modalLeadFormPhone"
                          autoComplete="tel"
                          className={classNames("formField__input app-lead-form-phone" , {
                            'focused' : userProfile ? true : false
                          })}
                        />
                        <label
                          className="formField__label"
                          htmlFor="modal_phone"
                        >
                          Téléphone{" "}
                        </label>
                      </div>
                      {/* </div>
                    <div className="app-form-control formControl app-lead-form-rol-control leadForm__fullwidth leadForm__rol hidden">
                      <div
                        className="app-form-field toggleButton "
                        role="group"
                        aria-labelledby="modal_rol"
                        aria-label="Indique-nous ton rôle dans ce mariage"
                      >
                        <input
                          type="radio"
                          name="RolInternational"
                          id="modal_rol1"
                          defaultValue={1}
                          className="app-lead-form-rol focused"
                        />
                        <label htmlFor="modal_rol1">Mariée </label>
                        <input
                          type="radio"
                          name="RolInternational"
                          id="modal_rol2"
                          defaultValue={2}
                          className="app-lead-form-rol focused"
                        />
                        <label htmlFor="modal_rol2">Marié </label>
                        <input
                          type="radio"
                          name="RolInternational"
                          id="modal_rol4"
                          defaultValue={4}
                          className="app-lead-form-rol focused"
                        />
                        <label htmlFor="modal_rol4">Autres </label>
                      </div>
                    </div> */}
                      {/* <div className="app-form-control formControl leadForm__input hidden">
                      <div
                        className="formField app-form-field  formField--outlined"
                        aria-labelledby="modal_region"
                      >
                        <i className="svgIcon svgIcon__angleDownBlood formField__icon">
                          <svg viewBox="0 0 19 11">
                            <path
                              d="M9.72 10.068a.773.773 0 01-.586-.334L1.011 1.608A.776.776 0 012.02.436l.087.075 7.676 7.676L17.459.51l.087-.075a.776.776 0 011.01 1.172l-8.123 8.126a.78.78 0 01-.585.334z"
                              fillRule="nonzero"
                            />
                          </svg>
                        </i>{" "}
                        <select
                          name="Provincia"
                          id="modal_region"
                          className="formField__input formField__input--select app-lead-form-region focused"
                        >
                          <option value={411}>Ain </option>
                          <option value={413}>Aisne </option>
                          <option value={414}>Allier </option>
                          <option value={415}>Alpes-de-Haute-Provence </option>
                          <option value={417}>Alpes-Maritimes </option>
                          <option value={418}>Ardèche </option>
                          <option value={419}>Ardennes </option>
                          <option value={420}>Ariège </option>
                          <option value={421}>Aube </option>
                          <option value={422}>Aude </option>
                          <option value={423}>Aveyron </option>
                          <option value={480}>Bas Rhin </option>
                          <option value={424}>Bouches-du-Rhône </option>
                          <option value={425}>Calvados </option>
                          <option value={426}>Cantal </option>
                          <option value={427}>Charente </option>
                          <option value={428}>Charente Maritime </option>
                          <option value={429}>Cher </option>
                          <option value={430}>Corrèze </option>
                          <option value={432}>Corse </option>
                          <option value={433}>Côte d'Or </option>
                          <option value={435}>Côtes d'Armor </option>
                          <option value={436}>Creuse </option>
                          <option value={489}>Deux-Sèvres </option>
                          <option value={431}>Dordogne </option>
                          <option value={437}>Doubs </option>
                          <option value={438}>Drôme </option>
                          <option value={501}>Essonne </option>
                          <option value={439}>Eure </option>
                          <option value={440}>Eure-et-Loir </option>
                          <option value={441}>Finistère </option>
                          <option value={442}>Gard </option>
                          <option value={444}>Gers </option>
                          <option value={445}>Gironde </option>
                          <option value={8843}>Guadeloupe </option>
                          <option value={8845}>Guyane </option>
                          <option value={481}>Haut Rhin </option>
                          <option value={443}>Haute-Garonne </option>
                          <option value={456}>Haute-Loire </option>
                          <option value={465}>Haute-Marne </option>
                          <option value={482}>Haute-Saône </option>
                          <option value={412}>Haute-Savoie </option>
                          <option value={497}>Haute-Vienne </option>
                          <option value={416}>Hautes-Alpes </option>
                          <option value={478}>Hautes-Pyrénées </option>
                          <option value={502}>Hauts-de-Seine </option>
                          <option value={446}>Hérault </option>
                          <option value={447}>Ille et Vilaine </option>
                          <option value={448}>Indre </option>
                          <option value={449}>Indre-et-Loire </option>
                          <option value={450}>Isère </option>
                          <option value={451}>Jura </option>
                          <option value={8842}>La Réunion </option>
                          <option value={452}>Landes </option>
                          <option value={453}>Loir-et-Cher </option>
                          <option value={454}>Loire </option>
                          <option value={457}>Loire Atlantique </option>
                          <option value={458}>Loiret </option>
                          <option value={459}>Lot </option>
                          <option value={460}>Lot-et-Garonne </option>
                          <option value={461}>Lozère </option>
                          <option value={462}>Maine et Loire </option>
                          <option value={463}>Manche </option>
                          <option value={464}>Marne </option>
                          <option value={8841}>Martinique </option>
                          <option value={466} selected>
                            Mayenne{" "}
                          </option>
                          <option value={467}>Meurthe-et-Moselle </option>
                          <option value={468}>Meuse </option>
                          <option value={469}>Morbihan </option>
                          <option value={470}>Moselle </option>
                          <option value={471}>Nièvre </option>
                          <option value={472}>Nord </option>
                          <option value={473}>Oise </option>
                          <option value={474}>Orne </option>
                          <option value={485}>Paris </option>
                          <option value={475}>Pas-de-Calais </option>
                          <option value={476}>Puy-de-Dôme </option>
                          <option value={477}>Pyrénées-Atlantiques </option>
                          <option value={479}>Pyrénées-Orientales </option>
                          <option value={455}>Rhône </option>
                          <option value={8846}>Saint Barthélemy </option>
                          <option value={8847}>Saint-Martin </option>
                          <option value={434}>Saône et Loire </option>
                          <option value={483}>Sarthe </option>
                          <option value={484}>Savoie </option>
                          <option value={487}>Seine-et-Marne </option>
                          <option value={486}>Seine-Maritime </option>
                          <option value={503}>Seine-Saint-Denis </option>
                          <option value={490}>Somme </option>
                          <option value={491}>Tarn </option>
                          <option value={492}>Tarn-et-Garonne </option>
                          <option value={500}>Territoire de Belfort </option>
                          <option value={505}>Val-d'Oise </option>
                          <option value={504}>Val-de-Marne </option>
                          <option value={493}>Var </option>
                          <option value={494}>Vaucluse </option>
                          <option value={495}>Vendée </option>
                          <option value={496}>Vienne </option>
                          <option value={498}>Vosges </option>
                          <option value={499}>Yonne </option>
                          <option value={488}>Yvelines </option>
                          <option value={8859}>Brabant flamand </option>
                          <option value={8857}>Brabant wallon </option>
                          <option value={8874}>Bruxelles - Capitale </option>
                          <option value={8849}>Hainaut </option>
                          <option value={8855}>Liège </option>
                          <option value={8875}>Limbourg </option>
                          <option value={8853}>Luxembourg </option>
                          <option value={8851}>Namur </option>
                          <option value={8871}>Berne </option>
                          <option value={8873}>Canton du Jura </option>
                          <option value={8867}>Fribourg </option>
                          <option value={8861}>Genève </option>
                          <option value={8869}>Neuchâtel </option>
                          <option value={8865}>Valais </option>
                          <option value={8863}>Vaud </option>
                          <option value={8878}>Monaco </option>
                        </select>
                        <label
                          className="formField__label"
                          htmlFor="modal_region"
                        >
                          Département
                        </label>
                      </div>
                    </div> */}
                      {/* <div className="app-form-control formControl leadForm__input hidden">
                      <div
                        className="formField app-form-field  formField--outlined"
                        aria-labelledby="modal_rolOthers"
                      >
                        <i className="svgIcon svgIcon__angleDownBlood formField__icon">
                          <svg viewBox="0 0 19 11">
                            <path
                              d="M9.72 10.068a.773.773 0 01-.586-.334L1.011 1.608A.776.776 0 012.02.436l.087.075 7.676 7.676L17.459.51l.087-.075a.776.776 0 011.01 1.172l-8.123 8.126a.78.78 0 01-.585.334z"
                              fillRule="nonzero"
                            />
                          </svg>
                        </i>{" "}
                        <select
                          name="RolOthers"
                          id="modal_rolOthers"
                          className="formField__input formField__input--select app-lead-form-rol-others focused"
                        >
                          <option value={3}>Famille </option>
                          <option value={4} selected>
                            Invités{" "}
                          </option>
                          <option value={5}>Prestataires </option>
                          <option value={6}>Presse </option>
                        </select>
                        <label
                          className="formField__label"
                          htmlFor="modal_rolOthers"
                        >
                          Votre rôle
                        </label>
                      </div>
                    </div> */}
                      
                    </div>
                    <div className="app-form-control formControl leadForm__fullwidth">
                        <div
                          className="formField app-form-field formField--date formField--outlined"
                          aria-labelledby="modal_date"
                        >
                          <i className="svgIcon svgIcon__calendar formField__icon">
                            <svg viewBox="0 0 38 38">
                              <path
                                d="M2.6 11.4h32.8V6.2h-4v2.4h-6.8V6.2H13.4v2.4H6.6V6.2h-4v5.2zm0 2v22h32.8v-22H2.6zm10.8-9.2h11.2V.6h6.8v3.6h6v33.2H.6V4.2h6V.6h6.8v3.6zM8.6 6.6h2.8v-4H8.6v4zm20.8 0v-4h-2.8v4h2.8z"
                                fillRule="nonzero"
                              />
                            </svg>
                          </i>{" "}
                          <input
                            type="date"
                            name="event_date"
                            onChange={(e) => {
                              formik.handleChange(e);
                              if (e.target.value.length > 0) {
                                document
                                  .getElementById("modal_date")
                                  ?.classList.add("focused");
                              }}}
                            value={formik.values.event_date}
                            id="modal_date"
                            autoComplete="off"
                            className="formField__input focused app-lead-form-date"
                          />
                          <label
                            className="formField__label"
                            htmlFor="modal_date"
                          >
                            Date de l'évènement
                          </label>
                        </div>
                      </div>
                      <div className="app-form-control formControl leadForm__fullwidth">
                        <div className="app-textarea-field">
                          <div
                            className="formField formField--outlined formField--action app-textarea-edit-button hidden"
                            tabIndex={0}
                          >
                            <i className="svgIcon svgIcon__editPost formField__icon">
                              <svg viewBox="0 0 18 18">
                                <path d="M.522.99h11.839a.522.522 0 01.094 1.035l-.094.009H1.044v14.163h13.703V6.539a.522.522 0 011.036-.094l.008.094v10.18c0 .256-.184.47-.428.514l-.094.008H.522a.522.522 0 01-.514-.428L0 16.719V1.51c0-.256.185-.47.428-.513L.522.989h11.839H.522zM17.456.555c.603.613.766 1.492.175 2.092l-7.234 7.353-.188.123-2.4.903a.522.522 0 01-.675-.667l.888-2.44.118-.188L15.374.38c.597-.607 1.476-.44 2.082.177zM15 2.246L8.964 8.385l-.46 1.265 1.23-.462 6.046-6.148-.78-.792zm1.118-1.134l-.385.39.78.793.373-.379c.12-.122.074-.375-.174-.627-.244-.249-.48-.293-.594-.177z" />
                              </svg>
                            </i>{" "}
                            <div className="formField__input formField__input--unlabeled">
                              <div>Éditez votre message</div>
                            </div>
                          </div>
                          <div
                            className="formField app-textarea-input app-form-field  formField--outlined formField__textarea--outlined"
                            aria-labelledby="modal_message"
                          >
                            <textarea
                              name="message"
                              onChange={(e) => {
                                formik.handleChange(e);
                                if (e.target.value.length > 0) {
                                  document
                                    .getElementById("modal_message")
                                    ?.classList.add("focused");
                                }}}
                              id="modal_message"
                              placeholder="Ecrivez un commentaire à l'entreprise"
                              className="formField__input focused app-lead-form-message app-lead-form-comment-field leadForm__message"
                              defaultValue={
                                "Bonjour, j'ai téléchargé le bon Elopement wedding - vidéo offerte et j'aimerais recevoir plus d'informations sur cette promotion. Merci d'avance."
                              }
                            />
                            <label className="formField__label">Message </label>
                          </div>
                        </div>
                      </div>
                  </div>
                  <div>
                    <button
                      type="submit"
                      data-test-id="modalLeadFormSubmit"
                      className="app-lead-form-submit leadForm__submit button button--block button--primary"
                      aria-label="Télécharger bon"
                    >
                      Téléchargez bon{" "}
                    </button>
                  </div>
                </form>
              </div>
            </section>
          </div>
          <div className="modal__overlay app-modal-overlay" />
        </div>
      )}
    </>
  );
}

import { create } from "zustand";

type PromotionModalType = {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  data : any;
  setData : (data : any) => void;
};

const usePromotionModal = create<PromotionModalType>((set) => ({
  isOpen: false,
  data : {},
  openModal: () => set({ isOpen: true }),
  closeModal: () => set({ isOpen: false }),
  setData : (data : any) => set({ data : data })
}));

export default usePromotionModal;

// renderRoutes

import React, { Fragment } from "react";
import { Route, Routes } from "react-router-dom";
import { Render } from "./components/auth-guard";

export const renderRoutes = (routes : any) => {
    return (
        <Routes>
            {routes.map((route : any, index : any) => {
                const { path, component, guard } = route;
                const Guard = guard !== undefined ? guard : Fragment;
                return (
                    <Route
                        key={index}
                        path={path}
                        element={
                            <Guard>
                                <Render
                                    component={component}
                                    accesRole={route.accesRole}
                                />
                            </Guard>
                        }
                    />
                );
            })}
        </Routes>
    );
};
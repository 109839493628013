import { create } from "zustand";

type OrderModalType = {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  data : any;
  setData : (data : any) => void;
};

const useOrderModal = create<OrderModalType>((set) => ({
  isOpen: false,
  data : {},
  openModal: () => set({ isOpen: true }),
  closeModal: () => set({ isOpen: false }),
  setData : (data : any) => set({ data : data })
}));

export default useOrderModal;

import { create } from "zustand";

type LoginModalType = {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
};

const useLoginModal = create<LoginModalType>((set) => ({
  isOpen: false,
  openModal: () => set({ isOpen: true }),
  closeModal: () => set({ isOpen: false }),
}));

export default useLoginModal;

import { create } from "zustand";

type ContactModalType = {
  isOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  data: any;
  setData: (data: any) => void;
};

const useContactModal = create<ContactModalType>((set) => ({
  isOpen: false,
  data: {},
  openModal: () => set({ isOpen: true }),
  closeModal: () => set({ isOpen: false }),
  setData: (data: any) => set({ data: data }),
}));

export default useContactModal;

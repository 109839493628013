import { ConfigValue } from "config";


export const getLoggedUser = () => {
  const user = localStorage.getItem(ConfigValue.AUTH_USER_KEY);
  if (!user) {
    return null;
  } else {
    return JSON.parse(user);
  }
};

export const setLoggedUser = (user: any) => {
  localStorage.setItem(ConfigValue.AUTH_USER_KEY, JSON.stringify(user));
};


export const removeLoggedUser = () => {
  localStorage.removeItem(ConfigValue.AUTH_USER_KEY);
};

export const getAuthToken = () => {
  return getLoggedUser()?.token;
};

export const Log = (type : any ,  data : any) => {
  console.log('-----' + type + '---------------')
  console.log(data)
  console.log('-----' + type + '---------------')

}


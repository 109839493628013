import React from "react";

export default function Alert({ type = "success"  , title , message , isDangerousHTML = false }
: { type?: string , title : string , message : string , isDangerousHTML?: boolean }) {
  return (
    <>
      <div className={"adminAlert adminAlert--" + type} id="alert">
        <p className="adminAlert__title">
          <strong>{title}</strong>
        </p>
        <div className="adminAlert__content">
          {isDangerousHTML ? (
            <div dangerouslySetInnerHTML={{ __html: message }}></div>
          ) : (
            <p>{message}</p>
          )}
          </div>
      </div>
    </>
  );
}


import { AuthResponse, LoginInput, RegisterServiceProviderInput, RegisterUserInput } from 'types';
import { API_ENDPOINTS } from './endpoints';
import { HttpClient } from './http-client';


class Client {
  users = {
    login: async (input: LoginInput) =>
      await HttpClient.post<AuthResponse>(API_ENDPOINTS.LOGIN, input),
    register: async (input: RegisterUserInput) =>
      await HttpClient.post<AuthResponse>(API_ENDPOINTS.REGISTER, input),
    logout: async () => await HttpClient.post<boolean>(API_ENDPOINTS.LOGOUT, {}),
    getCurrentUser: async () => await HttpClient.get<AuthResponse>(API_ENDPOINTS.CURRENT_USER, {}),
    socialLogin: async (input: any) => await HttpClient.post<AuthResponse>(API_ENDPOINTS.SOCIAL_LOGIN, input),
    changePassword: async (input: any) => await HttpClient.post<any>(API_ENDPOINTS.CHANGE_PASSWORD, input),
    changeEmail: async (input: any) => await HttpClient.post<any>(API_ENDPOINTS.CHANGE_EMAIL, input),
    changeSocialLogin: async (input: any) => await HttpClient.post<any>(API_ENDPOINTS.CHANGE_SOCIAL_LOGIN, input),
    forgotPassword: async (input: any) => await HttpClient.post<any>(API_ENDPOINTS.FORGOT_PASSWORD, input),
    resetPassword: async (input: any) => await HttpClient.post<any>(API_ENDPOINTS.RESET_PASSWORD, input),
    associateAccount: async (input: any) => await HttpClient.post<any>(API_ENDPOINTS.ASSOCIATE_ACCOUNT, input),
    updateProfile: async (input: any) => await HttpClient.post<any>(`${API_ENDPOINTS.UPDATE_PROFILE}`, input),
  };
  serviceProviders = {
    register: async (input: RegisterServiceProviderInput) =>
      await HttpClient.post<any>(API_ENDPOINTS.SERVICE_PROVIDERS, input),
    getOne: async (id: string, params: any) =>
      await HttpClient.get<any>(`${API_ENDPOINTS.SERVICE_PROVIDERS}/${id}`, params),
    index: async (params: any) =>
      await HttpClient.get<any>(API_ENDPOINTS.SERVICE_PROVIDERS, params),
    search: async (params: any) =>
      await HttpClient.get<any>(`/search${API_ENDPOINTS.SERVICE_PROVIDERS}`, params),
    update: async (id: string, input: RegisterServiceProviderInput) =>
      await HttpClient.put<any>(`${API_ENDPOINTS.SERVICE_PROVIDERS}/${id}`, input),
    addTeamMember: async (service_provider_id: string, input: any, options?: any) =>
      await HttpClient.post<any>(`${API_ENDPOINTS.TEAM_MEMBERS}/${service_provider_id}`, input, options),
    updateLocation: async (input?: any) =>
      await HttpClient.post<any>(API_ENDPOINTS.UPDATE_LOCATION, input),
    updateLogo: async (input?: any) =>
      await HttpClient.post<any>(API_ENDPOINTS.UPDATE_LOGO, input, { headers: { 'Content-Type': 'multipart/form-data' } }),
    removeTeamMember: async (service_provider_id: string, member_id: string) =>
      await HttpClient.delete<any>(`${API_ENDPOINTS.TEAM_MEMBERS}/${service_provider_id}/${member_id}`),
    updateTeamMember: async (service_provider_id: string, member_id: string, input: any, options?: any) =>
      await HttpClient.put<any>(`${API_ENDPOINTS.TEAM_MEMBERS}/${service_provider_id}/${member_id}`, input, options),
    getTeamMembers: async (service_provider_id: string, params?: any) =>
      await HttpClient.get<any>(`${API_ENDPOINTS.TEAM_MEMBERS}/${service_provider_id}`, params),
    addSocialMediaLink: async (service_provider_id: string, input: any, options?: any) =>
      await HttpClient.post<any>(`${API_ENDPOINTS.SOCIAL_MEDIA_LINKS}/${service_provider_id}`, input, options),
    updateServiceProviderServices: async (input: any) =>
      await HttpClient.post<any>(API_ENDPOINTS.UPDATE_SERVICE_PROVIDER_SERVICES, input),
    updateServiceProviderLocation: async (input: any) =>
      await HttpClient.post<any>(API_ENDPOINTS.UPDATE_SERVICE_PROVIDER_LOCATION, input),
    updateServiceProviderMinPrice: async (input: any) =>
      await HttpClient.post<any>(API_ENDPOINTS.UPDATE_SERVICE_PROVIDER_MIN_PRICE, input),
    evaluate: async (params?: any) =>
      await HttpClient.get<any>(API_ENDPOINTS.SERVICE_PROVIDER_EVALUATE, params),
    updateEmail: async (input: any) =>
      await HttpClient.post<any>(API_ENDPOINTS.UPDATE_MAIL, input),
    updateNotificationConfig: async (input: any) =>
      await HttpClient.post<any>(API_ENDPOINTS.NOTIFICATION_CONFIG, input),
    serviceProviderContactForm: async (input: any) =>
      await HttpClient.post<any>(API_ENDPOINTS.SERVICE_PROVIDER_CONTACT_FORM, input),

  };
  countries = {
    getAll: async (params: any) => await HttpClient.get<any>(API_ENDPOINTS.COUNTRIES, params),
  };
  cities = {
    getAll: async (params: any) => await HttpClient.get<any>(API_ENDPOINTS.CITIES, params),
  };
  sectors = {
    getAll: async (params: any) => await HttpClient.get<any>(API_ENDPOINTS.SECTORS, params),
    getOne: async (id: string, params: any) => await HttpClient.get<any>(`${API_ENDPOINTS.SECTORS}/${id}`, params),
  };
  activities = {
    getAll: async (params: any) => await HttpClient.get<any>(API_ENDPOINTS.ACTIVITIES, params),
    getOne: async (id: string, params: any) => await HttpClient.get<any>(`${API_ENDPOINTS.ACTIVITIES}/${id}`, params),
  };
  promotions = {
    getAll: async (params: any) => await HttpClient.get<any>(API_ENDPOINTS.PROMOTIONS, params),
    getOne: async (id: string, params: any) => await HttpClient.get<any>(`${API_ENDPOINTS.PROMOTIONS}/${id}`, params),
    create: async (input: any, options?: any) => await HttpClient.post<any>(API_ENDPOINTS.PROMOTIONS, input, options),
    update: async (id: string, input: any, options?: any) => await HttpClient.put<any>(`${API_ENDPOINTS.PROMOTIONS}/${id}`, input, options),
    delete: async (id: string) => await HttpClient.delete<any>(`${API_ENDPOINTS.PROMOTIONS}/${id}`),
  };
  medias = {
    getAll: async (params: any) => await HttpClient.get<any>(API_ENDPOINTS.MEDIAS, params),
    create: async (input: any, options?: any) => await HttpClient.post<any>(API_ENDPOINTS.MEDIAS, input, options),
    update: async (id: string, input: any, options?: any) => await HttpClient.put<any>(`${API_ENDPOINTS.MEDIAS}/${id}`, input, options),
    delete: async (id: string) => await HttpClient.delete<any>(`${API_ENDPOINTS.MEDIAS}/${id}`),
    setMainMedia: async (input: { owner_id: string, media_id: string }) => await HttpClient.post<any>(API_ENDPOINTS.SET_MAIN_MEDIA, input),
  };
  opinions = {
    getAll: async (params: any) => await HttpClient.get<any>(API_ENDPOINTS.OPINIONS, params),
    getOne: async (id: string, params: any) => await HttpClient.get<any>(`${API_ENDPOINTS.OPINIONS}/${id}`, params),
    create: async (input: any, options?: any) => await HttpClient.post<any>(API_ENDPOINTS.OPINIONS, input, options),
    update: async (id: string, input: any, options?: any) => await HttpClient.put<any>(`${API_ENDPOINTS.OPINIONS}/${id}`, input, options),
    delete: async (id: string) => await HttpClient.delete<any>(`${API_ENDPOINTS.OPINIONS}/${id}`),
  };
  quotes = {
    getAll: async (params: any) => await HttpClient.get<any>(API_ENDPOINTS.QUOTES, params),
    getOne: async (id: string, params: any) => await HttpClient.get<any>(`${API_ENDPOINTS.QUOTES}/${id}`, params),
    create: async (input: any, options?: any) => await HttpClient.post<any>(API_ENDPOINTS.QUOTES, input, options),
    update: async (id: string, input: any, options?: any) => await HttpClient.put<any>(`${API_ENDPOINTS.QUOTES}/${id}`, input, options),
    delete: async (id: string) => await HttpClient.delete<any>(`${API_ENDPOINTS.QUOTES}/${id}`),
    reply: async (input: any, options?: any) => await HttpClient.post<any>(API_ENDPOINTS.REPLY_QUOTE, input, options),
  };
  templates = {
    getAll: async (params: any) => await HttpClient.get<any>(API_ENDPOINTS.TEMPLATES_QUOTE, params),
    getOne: async (id: string, params: any) => await HttpClient.get<any>(`${API_ENDPOINTS.TEMPLATES_QUOTE}/${id}`, params),
    create: async (input: any, options?: any) => await HttpClient.post<any>(API_ENDPOINTS.TEMPLATES_QUOTE, input, options),
    update: async (id: string, input: any, options?: any) => await HttpClient.put<any>(`${API_ENDPOINTS.TEMPLATES_QUOTE}/${id}`, input, options),
    delete: async (id: string) => await HttpClient.delete<any>(`${API_ENDPOINTS.TEMPLATES_QUOTE}/${id}`),
  };
  events = {
    getAll: async (params: any) => await HttpClient.get<any>(API_ENDPOINTS.EVENTS, params),
    getOne: async (id: string, params: any) => await HttpClient.get<any>(`${API_ENDPOINTS.EVENTS}/${id}`, params),
    create: async (input: any, options?: any) => await HttpClient.post<any>(API_ENDPOINTS.EVENTS, input, options),
    update: async (id: string, input: any, options?: any) => await HttpClient.put<any>(`${API_ENDPOINTS.EVENTS}/${id}`, input, options),
    delete: async (id: string) => await HttpClient.delete<any>(`${API_ENDPOINTS.EVENTS}/${id}`),
  };
  eventTypes = {
    getAll: async (params: any) => await HttpClient.get<any>(API_ENDPOINTS.EVENT_TYPES, params),
  }
  services = {
    getAll: async (params: any) => await HttpClient.get<any>(API_ENDPOINTS.SERVICES, params),
    getOne: async (id: string, params: any) => await HttpClient.get<any>(`${API_ENDPOINTS.SERVICES}/${id}`, params),
  };
  contactForm = {
    send: async (input: any) => await HttpClient.post<any>(API_ENDPOINTS.CONTACT_FORM, input),
  };
  wishlist = {
    add: async (input: any) => await HttpClient.post<any>(API_ENDPOINTS.WISHLIST, input),
    remove: async (input: any) => await HttpClient.post<any>(API_ENDPOINTS.REMOVE_FROM_WISHLIST, input),
    getAll: async (params: any) => await HttpClient.get<any>(API_ENDPOINTS.WISHLIST, params),
    check: async (input: any) => await HttpClient.post<any>(API_ENDPOINTS.CHECK_WISHLIST, input),
  }
  announcements = {
    getAll: async (params: any) => await HttpClient.get<any>(API_ENDPOINTS.ANNOUNCEMENTS, params),
    getOne: async (id: string, params: any) => await HttpClient.get<any>(`${API_ENDPOINTS.ANNOUNCEMENTS}/${id}`, params),
    getActivities: async (params: any) => await HttpClient.get<any>(API_ENDPOINTS.ANNOUNCEMENT_ACTIVITY, params),
  }
  orders = {
    getAll: async (params: any) => await HttpClient.get<any>(API_ENDPOINTS.ORDERS, params),
    getOne: async (id: string, params: any) => await HttpClient.get<any>(`${API_ENDPOINTS.ORDERS}/${id}`, params),
    create: async (input: any, options?: any) => await HttpClient.post<any>(API_ENDPOINTS.ORDERS, input, options),
    delete: async (id: string) => await HttpClient.delete<any>(`${API_ENDPOINTS.ORDERS}/${id}`),
  };
  settings = {
    get: async () => await HttpClient.get<any>(API_ENDPOINTS.SETTINGS),
    update: async (input: any) => await HttpClient.post<any>(API_ENDPOINTS.SETTINGS, input),
  }
}
export default new Client();
import { lazy } from "react";
import { AuthProtected } from "./components/auth-guard.tsx";

export const clientRoutes = [
  {
    path: "/",
    component: lazy(() => import("../pages/client/home.tsx")),
    exact: true,
  },
  {
    path : "/search",
    component : lazy(() => import("../pages/client/search.tsx")),
    exact : true,
  },
  {
    path: "/service-provider/:id",
    component: lazy(() => import("../pages/client/sp-detail.tsx")),
    exact: true,
  },
  {
    path : '/service-provider/:id/opinion',
    component : lazy(() => import("../pages/client/opinion-form.tsx")),
    exact : true,
  },
  {
    path : '/service-providers',
    component : lazy(() => import("../pages/client/service-providers.tsx")),
    exact : true,
  },
  {
    path: "/login",
    component: lazy(() => import("../pages/auth/login.tsx")),
    exact: true,

  },
  {
    path : "/forgot-password",
    component : lazy(() => import("../pages/auth/forgot-password.tsx")),
    exact : true,
  },
  {
    path : "/reset-password",
    component : lazy(() => import("../pages/auth/reset-password.tsx")),
    exact : true,
  },
  {
    path: "/contact",
    component: lazy(() => import("../pages/client/contact.tsx")),
    exact: true,
  },
  {
    path: "/legal-terms",
    component: lazy(() => import("../pages/client/legal-terms.tsx")),
    exact: true,
  },
  {
    path: "/privacy-policy",
    component: lazy(() => import("../pages/client/privacy-policy.tsx")),
    exact: true,
  },
  {
    path: "/register",
    component: lazy(() => import("../pages/auth/register.tsx")),
    exact: true,
  },
  {
    path : "/complete-profile",
    component : lazy(() => import('../pages/auth/complete-profile.tsx')),
    exact : true
  },
  {
    path: "/dashboard",
    component: lazy(() => import("../pages/client/dashboard.tsx")),
    exact: true,
  },
  {
    path: "/dashboard/service-providers",
    component: lazy(() => import("../pages/client/my-service-providers.tsx")),
    exact: true,
  },
  {
    path: "/dashboard/wishlist",
    component: lazy(() => import("../pages/client/wishlist.tsx")),
    exact: true,
  },
  {
    path: "/dashboard/orders",
    component: lazy(() => import("../pages/client/my-orders.tsx")),
    exact: true,
  },
  {
    path: "/dashboard/account/:tab",
    component: lazy(() => import("../pages/client/account.tsx")),
    exact: true,
  },
  {
    path : '/announcement/home',
    component : lazy(() => import("../pages/client/announcement/home.tsx")),
    exact : true,
  },
  {
    path : '/announcement/detail/:id',
    component : lazy(() => import("../pages/client/announcement/detail.tsx")),
    exact : true,
  }
];

import { removeLoggedUser } from "data/client/utils";
import useProfile from "data/profile";
import { useEffect } from "react";
import { Route,  useLocation } from "react-router-dom";

const AuthProtected = (props : any) => {
  const { userProfile, token } = useProfile();
  useEffect(() => {
    if (!userProfile && !token) {
      removeLoggedUser();
    }
  }, [token, userProfile]);

  if (!userProfile && !token) {
     window.location.href = '/login';
  }

  return <>{props.children}</>;
};



const Render = ({ component: Component, ...rest } : any) => {
  return (
      <Component {...rest} />
  );
};

export { AuthProtected, Render };
import { useEffect, useState } from "react";
import { getLoggedUser } from "./client/utils"
import useAuth from "./auth";

const useProfile = () => {
  const userProfileSession = getLoggedUser();
  var tokenString =
  userProfileSession &&
  userProfileSession["token"];
  const [token, setToken] = useState<any>(tokenString);
  const [userProfile, setUserProfile] = useState<any>(
    userProfileSession ? userProfileSession : null
  );

  const { getCurrentUser } = useAuth();

  useEffect(() => {
    getCurrentUser();
    const userProfileSession = getLoggedUser();
    var token =
      userProfileSession &&
      userProfileSession["token"];
    setToken(token);
    setUserProfile(userProfileSession ? userProfileSession : null);
  }, []);

  return { userProfile , token};

}

export default useProfile;
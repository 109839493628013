import { lazy } from "react"
import { AuthProtected } from "./components/auth-guard.tsx"


export const AdminRoutes = [
  {
    path: "/admin",
    component: lazy(() => import("../pages/service-provider/admin/home.tsx")),
    exact: true,
    guard: AuthProtected,
  },
  {
    path: "/admin/store-front/:tab",
    component: lazy(() => import("../pages/service-provider/admin/store-front.tsx")),
    exact: true,
    guard: AuthProtected,
  },
  {
    path: "/admin/quote-request/:tab",
    component: lazy(() => import("../pages/service-provider/admin/quote-request.tsx")),
    exact: true,
    guard: AuthProtected,
  },
  {
    path: "/admin/opinion/:tab",
    component: lazy(() => import("../pages/service-provider/admin/opinion.tsx")),
    exact: true,
    guard: AuthProtected,
  },
  {
    path: "/admin/account/:tab",
    component: lazy(() => import("../pages/service-provider/admin/account.tsx")),
    exact: true,
    guard: AuthProtected,
  },
  {
    path: "/admin/complete-store-info",
    component: lazy(() => import("../pages/service-provider/admin/complete-store-front.tsx")),
    exact: true,
    guard: AuthProtected,
  },
  {
    path: "/admin/premuim",
    component: lazy(() => import("../pages/service-provider/admin/premuim.tsx")),
    exact: true,
    guard: AuthProtected,
  },
  // services provider

  {
    path: "/service-provider/register",
    component: lazy(() => import("../pages/auth/service-provider-register.tsx")),
    exact: true,
  },
  {
    path: "/service-provider",
    component: lazy(() => import("../pages/service-provider/website/landing.tsx")),
    exact: true,
  },
  {
    path: "/service-provider/visit/:tab",
    component: lazy(() => import("../pages/service-provider/website/visit.tsx")),
    exact: true,
  },
  {
    path: "/service-provider/premium",
    component: lazy(() => import("../pages/service-provider/website/premuim.tsx")),
    exact: true,
  },
  {
    path: "/service-provider/download-app",
    component: lazy(() => import("../pages/service-provider/website/mobile-app.tsx")),
    exact: true,
  },
]
import { useFormik } from "formik";
import useDeleteModal from "hooks/use-delete-modal";
import React from "react";
import { useNavigate } from "react-router-dom";

export default function DeleteModal() {
  const { closeModal, isOpen, deleteFn , description } = useDeleteModal();
  const navigate = useNavigate()
 
  return (
    <>
      {isOpen && (
        <div className="modal " role="dialog" >
          <div className="modal__content null" style={{ width : "400px" }}>
            <section className="app-auth-form authModal">
              <div className="authModal__header">
                <p className="authModal__title">Confirmation</p>
                <i
                  className="svgIcon svgIcon__close authModal__closeIcon app-modal-close"
                  onClick={closeModal}
                >
                  <svg viewBox="0 0 26 26">
                    <path
                      d="M12.983 10.862L23.405.439l2.122 2.122-10.423 10.422 10.423 10.422-2.122 2.122-10.422-10.423L2.561 25.527.439 23.405l10.423-10.422L.439 2.561 2.561.439l10.422 10.423z"
                      fillRule="nonzero"
                    />
                  </svg>
                </i>{" "}
              </div>
              <div className="authModal__content">
                <h4>{description}</h4>

                <div
                  className=""
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-around",
                    marginTop : 20
                  }}
                >
                  <button
                    className="btnFlat btnFlat--primary app-plantillas-save mr10"
                    type="submit"
                    onClick={() => deleteFn()}
                  >
                    Confirmer
                  </button>
                  <button
                    className="btnFlat btnFlat--grey app-plantillas-cancelar"
                    type="button"
                  >
                    Annuler
                  </button>
                </div>
              </div>
            </section>
          </div>
          <div className="modal__overlay app-modal-overlay" />
        </div>
      )}
    </>
  );
}
